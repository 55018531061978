import { DdmThing } from '../DdmThing';
import { DdmLocationAttributes } from './DdmLocationAttributes';

export class DdmSupplierAttributes {
  charge?: string;
  customerNumberSoldToParty?: string;
  dateOfManufacture?: string;
  deliveryItemNumber?: string;
  deliveryNumber?: string;
  dinId?: string;
  materialDescription?: string;
  obisCodes?: string;
  orderNumber?: string;
  outerPackagingId?: string;
  palletPackagingId?: string;
  productKey?: string;
  salesOrderItemNumber?: string;
  salesOrderNumber?: string;
  singlePackagingId?: string;
  yearOfConformity?: string;

  static fromThing(thing: DdmThing) {
    if (thing?.attributes) {
      return thing?.attributes['supplier-information' as keyof typeof thing.attributes] as DdmSupplierAttributes;
    } else return {} as DdmSupplierAttributes;
  }
}

export interface DdmOldSIMAttribute {
  vpn: string;
  vpnAddress: string;
  imei: string;
  simAccount: string;
  simId: string;
  simProvider: string;
}
export interface DdmVpnAttribute {
  provider: string;
}
export interface DdmNewSIMAttribute {
  account?: string;
  iccid?: string;
  provider?: string;
}

export type DdmAttributes = {
  vpn?: DdmVpnAttribute;
  serial: string;
  updatedAt?: string;
  billingUnitReference?: string;
  humanReadableId: string;
  location: DdmLocationAttributes;
  type: string;
  version: number;
  manufacturer: string;
  fleetId?: { iotcore: string };
  productKey?: string;
  devEUI?: string;
  supplier?: DdmSupplierAttributes;
  sim?: DdmOldSIMAttribute | DdmNewSIMAttribute;
  id?: string;
} | null;
