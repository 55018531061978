<nav class="flex" aria-label="Breadcrumb" *ngIf="pathSegments.length > 1">
  <ol class="inline-flex items-center space-x-1 md:space-x-3">
    <a [routerLink]="['/']"><mat-icon class="home ml-2">home</mat-icon></a>
    <mat-icon class="grey">chevron_right</mat-icon>
    <ddm-breadcrumb
      *ngFor="let segment of pathSegments; let i = index"
      [path]="segment"
      [onLinkClick]="i < pathSegments.length - 1 ? onLinkClick : null"
      [last]="i === pathSegments.length - 1"
    ></ddm-breadcrumb>
  </ol>
</nav>
