import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateRendererService {
  constructor(private dataService: DataService) {}

  render(json: string, template: string): Promise<string> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.templateRenderer + '/render';
      this.dataService.postApiRequest(url, { json: json, template: template }).subscribe((resp) => {
        resolve(resp);
      });
    });
  }

  evictCache(): Promise<string> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.templateRenderer + '/clearAllCaches';
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }
}
