import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupListLine } from 'src/app/models/PopupList';
import { DdmThingFeature } from 'src/app/models/things/DdmThingFeature';
import { DDMThingRSSI } from 'src/app/models/things/DDMThingRSSI';
import { DdmGridProperty } from 'src/app/models/things/properties/DdmGridProperty';

@Component({
  selector: 'ddm-thing-grid',
  templateUrl: './thing-grid.component.html',
  styleUrls: ['./thing-grid.component.scss'],
})
export class ThingGridComponent {
  constructor(private translate: TranslateService) {}

  // Content of a popup modal
  tableLines: PopupListLine[] = [];
  //content for the fields
  gridInfo: PopupListLine[] = [];

  @Input() set gridProperty(grid: DdmThingFeature<DdmGridProperty> | null) {
    if (grid) {
      const rssiThing = new DDMThingRSSI(grid?.properties.rssi);

      this.gridInfo = [
        {
          elements: [
            {
              content: this.translate.instant('things.thing.grid.receiveTime'),
              className: 'subtitle-2  ddm-font-color-grey pb-1',
            },
            {
              content: grid.properties.receiveTime !== undefined ? grid.properties.receiveTime : '-',
              className: ThingGridComponent.olderThanOneMonth(grid.properties.receiveTime)
                ? 'subtitle-2 rssi-too-low'
                : '',
              tooltip: this.translate.instant('things.thing.warning'),
            },
          ],
        },
        {
          elements: [
            {
              content: this.translate.instant('things.thing.grid.rssi'),
              className: 'subtitle-2  ddm-font-color-grey pb-1',
            },
            {
              content: grid.properties.rssi !== undefined ? `${grid.properties.rssi}` : '-',
              className: ` ${rssiThing.getRSSIClass()}`,
              tooltip: rssiThing.getRSSIMessage(),
            },
          ],
        },
        {
          elements: [
            {
              content: this.translate.instant('things.thing.grid.gateway'),
              className: 'subtitle-2  ddm-font-color-grey pb-1',
            },
            { content: grid.properties.gateway !== undefined ? grid.properties.gateway : '-', className: ' pb-1' },
          ],
        },
      ];

      this.tableLines = [
        {
          elements: [
            { content: this.translate.instant('things.thing.grid.receiveTime') },
            {
              content: grid.properties.receiveTime !== undefined ? grid.properties.receiveTime : '-',
              className: ThingGridComponent.olderThanOneMonth(grid.properties.receiveTime)
                ? 'subtitle-2 rssi-too-low'
                : '',
              tooltip: this.translate.instant('things.thing.warning'),
            },
          ],
        },
        {
          elements: [
            { content: this.translate.instant('things.thing.grid.rssi') },
            {
              content: grid.properties.rssi !== undefined ? `${grid.properties.rssi}` : '-',
              className: `subtitle-2 ${rssiThing.getRSSIClass()}`,
              tooltip: rssiThing.getRSSIMessage(),
            },
          ],
        },
        {
          elements: [
            { content: this.translate.instant('things.thing.grid.deliveryTime') },
            { content: grid.properties.deliveryTime !== undefined ? grid.properties.deliveryTime : '-' },
          ],
        },
        {
          elements: [
            { content: this.translate.instant('things.thing.grid.gateway') },
            { content: grid.properties.gateway !== undefined ? grid.properties.gateway : '-' },
          ],
        },
      ];

      if (grid.properties.bandwidth !== undefined) {
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.spreadingFactor') },
            { content: grid.properties.spreadingFactor !== undefined ? grid.properties.spreadingFactor : '-' },
          ],
        });
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.bandwidth') },
            { content: grid.properties.bandwidth !== undefined ? grid.properties.bandwidth : '-' },
          ],
        });
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.mic') },
            { content: grid.properties.mic !== undefined ? grid.properties.mic : '-' },
          ],
        });
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.snr') },
            { content: grid.properties.snr !== undefined ? grid.properties.snr : '-' },
          ],
        });
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.dataRate') },
            { content: grid.properties.dataRate !== undefined ? grid.properties.dataRate : '-' },
          ],
        });
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.codeRate') },
            { content: grid.properties.codeRate !== undefined ? grid.properties.codeRate : '-' },
          ],
        });
      } else {
        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.radiomodule') },
            { content: grid.properties.radiomodule !== undefined ? grid.properties.radiomodule : '-' },
          ],
        });

        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.nodeMode') },
            { content: grid.properties.nodeMode !== undefined ? grid.properties.nodeMode : '-' },
          ],
        });

        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.nodeType') },
            { content: grid.properties.nodeType !== undefined ? grid.properties.nodeType : '-' },
          ],
        });

        this.tableLines.push({
          elements: [
            { content: this.translate.instant('things.thing.grid.source') },
            { content: grid.properties.source !== undefined ? grid.properties.source : '-' },
          ],
        });
      }
    }
  }
  static olderThanOneMonth(time: string): boolean {
    if (time === undefined) return false;
    const dateString = time.substring(0, 10);
    const date = new Date(dateString);
    const now = new Date();
    if (ThingGridComponent.getDiffInDays(date, now) >= 30) {
      return true;
    }
    return false;
  }

  static getDiffInDays(date1: Date, date2: Date): number {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  }

  isValidDate(date?: string) {
    return date ? Date.parse(date) : false;
  }
}
