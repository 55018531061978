<!-- Main page -->
<ng-container *ngIf="isLoggedIn">
  <mat-sidenav-container>
    <mat-sidenav #sidenav position="end" [disableClose]="true" (openedChange)="handleSidenavOpenedChange($event)">
      <div class="pt-2 pr-2 sticky top-0 flex justify-end bg-white">
        <button mat-icon-button (click)="sidenav.close()" data-testid="closeButton">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="pl-10 pr-14 pb-10">
        <ng-container *ngIf="sidenavData$ | async as sidenavData">
          <ng-container
            *ngIf="sidenavData.opened"
            [ngComponentOutlet]="sidenavData.component"
            [ngComponentOutletInjector]="sidenavData.injector"
          ></ng-container
        ></ng-container>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<!-- Login screen -->
<div *ngIf="!isLoggedIn">
  <div class="align-center">
    <router-outlet></router-outlet>
  </div>
</div>
