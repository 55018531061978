import { Directive, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[ddmBackButton]',
})
export class BackButtonDirective {
  @Input() preserveParams?: boolean = true;
  @Input() beforeRedirect?: Function;

  navHistory: string[] = [];

  constructor(private location: Location) {}

  @HostListener('click')
  onClick(): void {
    if (this.beforeRedirect) {
      this.beforeRedirect();
    }

    this.location.back();
  }
}
