/**
 *  https://www.eclipse.org/ditto/http-api-doc.html#/Things-Search/get_search_things
 */
export enum ThingFilterPredicates {
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER = 'gt',
  GREATER_OR_EVEN = 'ge',
  LOWER = 'le',
  LOWER_OR_EQUAL = 'lt',
  CONTAINS = 'in',
  LIKE = 'like',
  INSENSITIVE_LIKE = 'ilike',
  EXISTS = 'exists',
}

/**
 * https://www.eclipse.org/ditto/http-api-doc.html#/Things-Search/get_search_things
 *
 * Defines how the active filterings of one filter are connected
 */
export enum ThingFilterLogic {
  AND = 'and',
  NOT = 'not',
  OR = 'or',
}

/**
 * Everything the things service needs to know when requesting filtered things from ditto.
 *
 * https://www.eclipse.org/ditto/http-api-doc.html#/Things-Search/get_search_things
 *
 * @param active contains all active filter values e.g. ['QDS', 'EIE']
 * @param propperty path in thing, where the filter shall be applied e.g. 'attributes/manufacturer'
 * @param predicate how the active values shall be interpreted e.g. **eq**(attributes/manufacturer, 'QDS')
 * @param logical how the queries resulting from the active filter values shall be connected
 * @param mustNotMatch if true the whole query is encapsulated with not e.g. **not**(or(eq(), and()))
 * @param isNumber whether the values shall be interpreted as number or not
 */
export type ThingRequestFilter = {
  active: string[];
  property: string | string[];
  predicate: ThingFilterPredicates;
  logical: ThingFilterLogic;
  supportIntAndString?: boolean;
  falseOrNotExists?: boolean;
  translateableValues?: boolean;
  translations?: { [index: string]: string };
  mustNotMatch?: boolean;
  isNumber?: boolean;
};

/**
 * Everything the UI must know
 *
 * @param title the translatable title for the filter that shall be displayed e.g. 'devices.manufacturer'
 * @param values all values the filter can have (for check boxes). If empty, an input field will be displayed
 * @param valueToLable optional - if set, each value will be processed by this function prior visualization
 */
export type ThingUiFilter = {
  title: string;
  values: (boolean | string | number)[];
  valueToLabel?: (value: boolean | string | number) => string;
};

/**
 * Everything relevant for filtering against ditto
 */
export type ThingFilter = ThingRequestFilter & ThingUiFilter;
