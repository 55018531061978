import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { from, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../data.service';
import { COGNITO_GROUPS, CognitoGroups } from './auth.model';

const USER_POOL_DATA = {
  UserPoolId: environment.cognitoUserPoolId,
  ClientId: environment.cognitoClientId,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router) {}

  getCurrentUser(): CognitoUser | null {
    const userPool = new CognitoUserPool(USER_POOL_DATA);
    return userPool.getCurrentUser();
  }

  isAuthenticated(): boolean {
    let isAuthenticated = false;
    if (this.getCurrentUser()) {
      isAuthenticated = true;
    }
    return isAuthenticated;
  }

  canViewFuota(): Observable<boolean> {
    return from(this.getCognitoAccessToken()).pipe(
      map((token) => doesTokenHaveGroups(token, [COGNITO_GROUPS.FUOTA_READ, COGNITO_GROUPS.FUOTA_WRITE])),
    );
  }

  canEditFuota(): Observable<boolean> {
    return from(this.getCognitoAccessToken()).pipe(
      map((token) => doesTokenHaveGroups(token, [COGNITO_GROUPS.FUOTA_WRITE])),
    );
  }

  login(
    username: string,
    password: string,
    redirectUrl: string,
    callbacks?: { onSuccess?: () => void; onFailure?: () => void },
  ) {
    const userPool = new CognitoUserPool(USER_POOL_DATA);
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        callbacks?.onSuccess?.();
        this.router.navigateByUrl(redirectUrl).then(() => window.location.reload());
      },
      onFailure: () => {
        callbacks?.onFailure?.();
        console.error('Login failed: Invalid password or username!');
      },
    });
  }

  async getCognitoAccessToken() {
    return new Promise<CognitoAccessToken>((resolve, reject) => {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        currentUser.getSession(function (err: Error | null, session: CognitoUserSession | null) {
          if (err) {
            reject(err);
          } else if (!session) {
            reject('No session available');
          } else {
            resolve(session.getAccessToken());
          }
        });
      } else {
        reject({ error: { message: DataService.USER_NOT_FOUND_MESSAGE } });
      }
    });
  }

  async getAuthToken() {
    const authUser = await this.getCognitoAccessToken();
    return authUser.getJwtToken();
  }

  async isAdmin() {
    const authUser = await this.getCognitoAccessToken();
    const roles = authUser.payload['cognito:groups'] as string[];
    // TODO eventually this must be replaced with a permission check on Ditto instead of looking at JWT claims
    return (
      roles.includes(COGNITO_GROUPS.SUBMETERING_ADMIN_WRITE) || roles.includes(COGNITO_GROUPS.SMARTHEATING_ADMIN_WRITE)
    );
  }

  logout(): void {
    let redirectUrl = this.router.url;
    if (this.isAuthenticated()) {
      this.getCurrentUser()?.signOut();
      redirectUrl = ''; // no redirect in case of intended logout
    }
    if (this.router.url != '/login') {
      this.router.navigate(['/login'], { queryParams: { redirect: redirectUrl } }).then(() => window.location.reload());
    }
  }
}

function doesTokenHaveGroups(accessToken: any, groups: CognitoGroups[]): boolean {
  const tokenGroups = accessToken.payload['cognito:groups'] as string[];
  return groups.some((group) => tokenGroups.includes(group));
}
