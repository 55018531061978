<div class="h-full grid grid-cols-1 p-6 space-y-12">
  <div class="grid content-start justify-items-stretch">
    <div class="mb-8">
      <img *ngIf="!env.qa && !env.production" src="/assets/images/bxInsight_header_DEV.png" />

      <img *ngIf="env.qa && env.production" src="/assets/images/bxInsight_header_QA.png" />

      <img *ngIf="env.production && !env.qa" src="/assets/images/bxInsight_header_PROD.png" />
    </div>

    <ddm-menu-item
      icon="home"
      text="{{ 'side-bar.business-entities' | translate }}"
      linkPath="/business-entities"
      iconSide="left"
    >
    </ddm-menu-item>

    <ddm-menu-item icon="devices" text="{{ 'side-bar.devices' | translate }}" linkPath="/devices" iconSide="left">
    </ddm-menu-item>

    <ddm-menu-item icon="devices" text="{{ 'side-bar.gateways' | translate }}" linkPath="/gateways" iconSide="left">
    </ddm-menu-item>

    <ddm-menu-item
      icon="engineering"
      text="{{ 'side-bar.maintenance-orders' | translate }}"
      linkPath="/maintenance-orders"
      iconSide="left"
    >
    </ddm-menu-item>

    <ddm-menu-item
      icon="devices"
      text="{{ 'side-bar.fleet-management' | translate }}"
      linkPath="/fleetmanagement"
      iconSide="left"
    >
    </ddm-menu-item>

    <ddm-menu-item
      *ngIf="canViewFuota$ | async as canViewFuota"
      icon="devices"
      text="{{ 'side-bar.fuota' | translate }}"
      linkPath="/fuota"
      iconSide="left"
      data-testid="firmwareUpdate"
    >
    </ddm-menu-item>

    <ddm-menu-item icon="description" text="{{ 'side-bar.els' | translate }}" linkPath="/els" iconSide="left">
    </ddm-menu-item>

    <ddm-menu-item
      icon="integration_instructions"
      text="{{ 'side-bar.dev-tools' | translate }}"
      [highlight]="false"
      iconSide="left"
      [isChild]="false"
      [toggle]="devToolsOpen"
      (click)="devToolsOpen = !devToolsOpen; $event.preventDefault(); $event.stopPropagation()"
    >
      <ddm-menu-item
        icon="description"
        text="{{ 'side-bar.mbus' | translate }}"
        linkPath="/mbus"
        iconSide="left"
        [isChild]="true"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      ></ddm-menu-item>

      <ddm-menu-item
        icon="timeline"
        text="{{ 'side-bar.history' | translate }}"
        linkPath="/history"
        iconSide="left"
        [isChild]="true"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
      </ddm-menu-item>

      <ddm-menu-item
        icon="devices"
        text="{{ 'side-bar.aes-keys' | translate }}"
        linkPath="/aes-keys"
        iconSide="left"
        [isChild]="true"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
      </ddm-menu-item>

      <ddm-menu-item
        icon="devices"
        text="{{ 'side-bar.deliver-mbus' | translate }}"
        linkPath="/mbusdeliver"
        iconSide="left"
        [isChild]="true"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
      </ddm-menu-item>
    </ddm-menu-item>
  </div>

  <div class="grid self-end place-items-start justify-items-stretch">
    <div class="ddm-border-0 ddm-btn-typo-margin"></div>

    <div class="overline ddm-btn-typo-margin pl-3">{{ "settings.loggedIn" | translate }}</div>

    <div class="mt-1 mb-4 pl-3">{{ this.userName }}</div>

    <ddm-menu-item icon="settings" text="{{ 'side-bar.settings' | translate }}" linkPath="/settings"> </ddm-menu-item>

    <ddm-menu-item
      [callBack]="this.authService.logout.bind(this.authService)"
      icon="logout"
      [highlight]="false"
      text="{{ 'app.logout' | translate }}"
    >
    </ddm-menu-item>
  </div>
</div>
