import { Component, Input } from '@angular/core';

@Component({
  selector: 'ddm-nav-back-button',
  templateUrl: './nav-back-button.component.html',
  styleUrls: ['./nav-back-button.component.scss'],
})
export class NavBackButtonComponent {
  @Input() disabled: boolean = false;
  @Input() onBack?: Function;
  @Input() preserveParams?: boolean = true;
}
