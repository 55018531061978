<mat-toolbar class="align-bottom">
  <span *ngIf="isLoggedIn"
    >{{ "footer.logged_in" | translate }} <b>{{ username }}</b></span
  >
  <span *ngIf="!isLoggedIn">{{ "footer.not_logged_in" | translate }}</span>

  <span class="empty-spacer"></span>

  <span class="allow-whitespace">©2022 </span>
  <a href="https://beyonnex.io/">beyonnex.io</a>
</mat-toolbar>
