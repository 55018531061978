import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { SidenavData, SidenavDataWithComponent } from './sidenav.service.model';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private readonly _sidenavData$ = new BehaviorSubject<SidenavData>({ opened: false });
  readonly sidenavData$ = this._sidenavData$.pipe(shareReplay(1));

  open(data: Omit<SidenavDataWithComponent, 'opened'>): void {
    this._sidenavData$.next({ opened: true, ...data });
  }

  close(): void {
    this._sidenavData$.next({ opened: false });
  }
}
