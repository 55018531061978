import { DdmThing } from './DdmThing';

export class DDMThingSimInfo {
  public status: string = '';
  public offering: string = '';
  public dataConsumedBytes: string = '';
  public updatedAt: string = '';
  public imei: string = '';
  public country: string = '';
  public currentSessionDurationSeconds: string = '';
  public currentSessionDataConsumedBytes: string = '';
  public lastUptime: string = '';

  static fromThing(thing: DdmThing): DDMThingSimInfo | null {
    const thingSimInfo = thing.getSimInfo();
    const simInfoItem = new DDMThingSimInfo();
    simInfoItem.status = thingSimInfo?.properties.status ?? '-';
    simInfoItem.offering = thingSimInfo?.properties.offering ?? '-';
    simInfoItem.dataConsumedBytes = thingSimInfo?.properties.dataConsumedBytes ?? '-';
    simInfoItem.updatedAt = thingSimInfo?.properties.updatedAt ?? '-';
    simInfoItem.imei = thingSimInfo?.properties.imei ?? '-';
    simInfoItem.country = thingSimInfo?.properties.country ?? '-';
    simInfoItem.currentSessionDurationSeconds = thingSimInfo?.properties.currentSessionDurationSeconds ?? '-';
    simInfoItem.currentSessionDataConsumedBytes = thingSimInfo?.properties.currentSessionDataConsumedBytes ?? '-';
    simInfoItem.lastUptime = thingSimInfo?.properties.lastUptime ?? '-';
    return simInfoItem;
  }
}
