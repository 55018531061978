import { Injectable } from '@angular/core';
import { DeviceType } from '../models/DeviceType';
import { DataService } from './data.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceRepoService {
  templateInEditChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setTemplateInEdit(template: string) {
    this.templateInEditChanged.next(template);
  }

  constructor(private dataService: DataService) {}

  getDeviceTypes(): Promise<DeviceType[]> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.devicesRepo;
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  getDeviceType(id: string): Promise<DeviceType> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.devicesRepo + '/' + id;
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  getDeviceTypeObservable(id: string): Observable<DeviceType> {
    const url = DataService.SERVICES.devicesRepo + '/' + id;
    return this.dataService.getApiRequest(url);
  }

  createDeviceType(dt: DeviceType): Promise<string> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.devicesRepo;
      this.dataService.postApiRequest(url, dt).subscribe((response) => {
        resolve(response);
      });
    });
  }

  updateDeviceType(dt: DeviceType): Promise<DeviceType> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.devicesRepo + '/' + dt.id;
      this.dataService.putApiRequest(url, dt).subscribe((response) => {
        resolve(response);
      });
    });
  }

  deleteDeviceType(dt: DeviceType): Promise<DeviceType> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.devicesRepo + '/' + dt.id;
      this.dataService.deleteApiRequest(url, dt).subscribe((response) => {
        resolve(response);
      });
    });
  }
}
