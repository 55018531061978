import { Thing } from '@eclipse-ditto/ditto-javascript-client-dom';
import { ThingGridComponent } from 'src/app/components/molecules/thing-grid/thing-grid.component';
import { DdmNamedFeature, DdmNamedProperty, DdmThingFeature } from './DdmThingFeature';
import { DDMThingHealthcheck } from './DDMThingHealthcheck';
import { DDMThingLocation } from './DDMThingLocation';
import { DdmNamedProperties } from './DdmThingProperties';
import { DDMThingRSSI } from './DDMThingRSSI';
import { DDMThingSimInfo } from './DDMThingSimInfo';
import { DittoPropertyAnalytics } from './DDMThingStatics';
import { DDMThingStatus } from './DDMThingStatus';
import { DDMThingType } from './DDMThingType';
import { DdmComControllerProperty } from './properties/DdmComControllerProperty';
import { DdmGridProperty } from './properties/DdmGridProperty';
import { DdmHealthCheckProperty } from './properties/DdmHealthCheckProperty';
import { DdmLocationAttributes, DdmRoom, DdmRoomGroup } from './properties/DdmLocationAttributes';
import { DdmMeteringProperty } from './properties/DdmMeteringProperty';
import { DdmPropertyDeviceState } from './properties/DdmPropertyDeviceState';
import { DdmPropertyStatus } from './properties/DdmPropertyStatus';
import { DdmExpValue, DdmSimpleValue } from './properties/DdmPropertyTypes';
import { DdmReadoutProperty } from './properties/DdmReadoutProperty';
import { DdmAttributes, DdmSupplierAttributes } from './properties/DdmThingAttributes';

/**
 * The DdmThing has the functionality to deliver all known
 * feature types.
 */
export class DdmThing extends Thing {
  readonly asString: string;
  maintenanceOrderStatus: string = '';

  public readonly cssColor: string = 'rgba(255,255,255, 0.3)';
  public readonly chartColor: string = '#808080';

  public rssi: DDMThingRSSI | null;
  public status: DDMThingStatus | null;
  public healthCheck: DDMThingHealthcheck | null;
  public simInformation: DDMThingSimInfo | null;
  public location: DDMThingLocation | null;
  public supplier?: DdmSupplierAttributes;

  constructor(thing: Thing) {
    super(
      thing.thingId,
      thing.policyId,
      thing.attributes,
      thing.features,
      thing._revision,
      thing._modified,
      thing.definition,
      thing._metadata,
      thing._created,
    );

    this.rssi = DDMThingRSSI.fromThing(this);
    this.status = DDMThingStatus.fromThing(this);
    this.healthCheck = DDMThingHealthcheck.fromThing(this);
    this.simInformation = DDMThingSimInfo.fromThing(this);
    this.location = DDMThingLocation.fromThing(this);
    this.supplier = DdmSupplierAttributes.fromThing(this);
    // Pretty prints the JSON representation of the thing.
    // replace underscore since stringify takes private members which start with '_'
    this.asString = JSON.stringify(thing, null, 2).replace(new RegExp(' {2}"_', 'g'), '  "');
  }

  // Implementation of IDisplayableThing
  toPropertyList(): { key: string; value: string }[] {
    const attributes = this.getAttributes();
    return attributes
      ? [
          { key: 'default.manufacturer', value: attributes.manufacturer },
          { key: 'default.type', value: attributes.type?.toString() ?? '' },
          { key: 'default.serial', value: attributes.serial },
        ]
      : [];
  }

  toString(): string {
    return this.asString;
  }

  public static thingIdFromIdstr(idstr: string): string {
    return 'io.beyonnex:' + idstr;
  }

  public static idStrFromThingId(thingId: string): string {
    return thingId.replace('io.beyonnex:', '');
  }

  getSimpleValues(): DdmNamedProperties<DdmSimpleValue>[] | null {
    const simpleValues: DdmNamedProperties<DdmSimpleValue>[] = [];

    if (this.features) {
      // in each feature
      for (const [featureId, feature] of Object.entries(this.features)) {
        let newFeature: DdmNamedProperties<DdmSimpleValue> | undefined;

        if (feature.properties) {
          // analyse each property
          Object.entries(feature.properties).forEach(([propertyId, property]) => {
            // if its data type matched DdmSimpleValue
            if (
              typeof property === 'object' &&
              property !== null &&
              property.hasOwnProperty('value') &&
              property.hasOwnProperty('date') &&
              property.hasOwnProperty('unit') &&
              !property.hasOwnProperty('rawExp')
            ) {
              if (!newFeature) {
                // store each matching instance with featureId and propertyId
                newFeature = {
                  featureId: featureId,
                  properties: [],
                };
              }
              newFeature.properties.push({
                propertyId: propertyId,
                date: property['date'],
                unit: property['unit'],
                value: property['value'],
              });
            }
          });
        }
        if (newFeature) {
          simpleValues.push(newFeature);
        }
      }
    }
    return simpleValues.length > 0 ? simpleValues : null;
  }

  getExpValues(): DdmNamedProperties<DdmExpValue>[] | null {
    const simpleValues: DdmNamedProperties<DdmExpValue>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        let newFeature: DdmNamedProperties<DdmExpValue> | undefined;

        if (feature.properties) {
          Object.entries(feature.properties).forEach(([propertyId, property]) => {
            if (
              typeof property === 'object' &&
              property !== null &&
              property.hasOwnProperty('value') &&
              property.hasOwnProperty('date') &&
              property.hasOwnProperty('unit') &&
              property.hasOwnProperty('rawExp') &&
              property.hasOwnProperty('rawValue')
            ) {
              if (!newFeature) {
                newFeature = {
                  featureId: featureId,
                  properties: [],
                };
              }
              newFeature.properties.push({
                propertyId: propertyId,
                date: property['date'],
                unit: property['unit'],
                value: property['value'],
                rawExp: property['rawExp'],
                rawValue: property['rawValue'],
              });
            }
          });
        }
        if (newFeature) {
          simpleValues.push(newFeature);
        }
      }
    }
    return simpleValues.length > 0 ? simpleValues : null;
  }

  getLastTransmission(): string {
    let lastTransmission = '';
    const comControllers = this.getComControllers();
    if (comControllers) {
      lastTransmission = comControllers[0].properties.lastTransmission;
      return lastTransmission;
    } else {
      return '';
    }
  }
  getAttributes(): DdmAttributes {
    const roomGroupData: DdmRoomGroup = {
      ordinal: null,
      number: '-',
      id: '-',
    };

    const roomData: DdmRoom = {
      floorLevel: '-',
      type: '-',
    };

    const locationAttributesData: DdmLocationAttributes = {
      businessEntityId: '-',
      crmId: '-',
      label: '-',
      buildingId: '-',
      roomGroup: roomGroupData,
      room: roomData,
      mountedOn: '-',
      isAdvancedRegistrationMeter: '-',
      updatedAt: '-',
    };

    const attributeData: DdmAttributes = {
      serial: 'NA',
      updatedAt: 'NA',
      humanReadableId: 'NA',
      billingUnitReference: 'NA',
      location: locationAttributesData,
      type: 'NA',
      devEUI: 'NA',
      version: 1,
      manufacturer: 'NA',
      productKey: 'NA',
    };

    const attributes = this.attributes;
    if (attributes) {
      // we have different data format for type ROOM than all other things types, then we need this modification for only ROOM type:
      if (['ROOM', 'PROPERTY'].includes(attributes['type' as keyof typeof attributes])) {
        const ordinal = attributes['ordinal' as keyof typeof attributes];
        const number = attributes['number' as keyof typeof attributes];
        const billingUnitReference = attributes['billingUnitReference' as keyof typeof attributes];
        const id = attributes['id' as keyof typeof attributes];
        const propertyId = attributes['propertyId' as keyof typeof attributes];
        const crmId = attributes['externalId' as keyof typeof attributes];
        const humanReadableId = attributes['humanReadableId' as keyof typeof attributes];
        const buildingId = attributes['buildingId' as keyof typeof attributes];
        attributes['location' as keyof typeof attributes] = {
          propertyId: propertyId,
          crmId: crmId,
          buildingId: buildingId,
          humanReadableId: humanReadableId,
          billingUnitReference: billingUnitReference,
          room: {
            ordinal: ordinal,
            number: number,
            id: id,
          },
        } as never;
      }
      Object.entries(attributes).forEach(([key, value]) => {
        switch (key) {
          case 'type':
            if (value) {
              attributeData.type = new DDMThingType(value).toString();
            } else {
              attributeData.type = value;
            }
            break;
          case 'location':
            attributeData.location = <DdmLocationAttributes>value;
            break;
          case 'serial':
            attributeData.serial = value;
            break;
          case 'updatedAt':
            attributeData.updatedAt = value;
            break;
          case 'humanReadableId':
            attributeData.humanReadableId = value;
            break;
          case 'billingUnitReference':
            attributeData.billingUnitReference = value;
            break;
          case 'manufacturer':
            attributeData.manufacturer = value;
            break;
          case 'productKey':
            attributeData.productKey = value;
            break;
          case 'version':
            attributeData.version = value;
            break;
          case 'fleetId':
            attributeData.fleetId = value;
            break;
          case 'devEUI':
            attributeData.devEUI = value;
            break;
          case 'sim':
            attributeData.sim = value;
            break;
          case 'vpn':
            attributeData.vpn = value;
            break;
          case 'id':
            attributeData.id = value;
        }
      });
    } else {
      return null;
    }
    return attributeData;
  }

  getStates(): DdmNamedProperty<DdmPropertyStatus>[] | null {
    const states: DdmNamedProperty<DdmPropertyStatus>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (feature.properties?.status) {
          states.push({
            featureId: featureId,
            property: feature.properties?.status,
          });
        }
      }
    }
    return states.length > 0 ? states : null;
  }

  getDeviceState(): DdmNamedProperty<DdmPropertyDeviceState>[] | null {
    const states: DdmNamedProperty<DdmPropertyDeviceState>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (feature.properties?.states) {
          const stateArray: DdmPropertyDeviceState[] = feature.properties.states;
          stateArray.forEach((source) =>
            states.push({
              featureId: featureId,
              property: source,
            }),
          );
        }
      }
    }
    return states.length > 0 ? states : null;
  }

  getFaults(): DdmNamedProperty<DdmPropertyStatus>[] | null {
    const faults: DdmNamedProperty<DdmPropertyStatus>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (feature.properties?.fault) {
          faults.push({
            featureId: featureId,
            property: feature.properties?.fault,
          });
        }
      }
    }
    return faults.length > 0 ? faults : null;
  }

  getGrid(): DdmThingFeature<DdmGridProperty> | null {
    if (this.features?.grid) {
      const grid: DdmThingFeature<DdmGridProperty> = {
        definition: this.features?.grid?.definition,
        properties: {
          receiveTime: this.features?.grid?.properties?.receiveTime,
          rssi: this.features?.grid?.properties?.rssi,
          deliveryTime: this.features?.grid?.properties?.deliveryTime,
          radiomodule: this.features?.grid?.properties?.radiomodule,
          nodeMode: this.features?.grid?.properties?.nodeMode,
          source: this.features?.grid?.properties?.source,
          nodeType: this.features?.grid?.properties?.nodeType,
          gateway: this.features?.grid?.properties?.gateway,
          bandwidth: this.features?.grid?.properties?.bandwidth,
          codeRate: this.features?.grid?.properties?.codeRate,
          dataRate: this.features?.grid?.properties?.dataRate,
          mic: this.features?.grid?.properties?.mic,
          snr: this.features?.grid?.properties?.snr,
          spreadingFactor: this.features?.grid?.properties?.spreadingFactor,
        },
      };
      return grid;
    } else {
      return null;
    }
  }

  getHealthCheck(): DdmThingFeature<DdmHealthCheckProperty> | null {
    const healthCheck: DdmThingFeature<DdmHealthCheckProperty> = {
      properties: {
        source: this.features?.healthCheck?.properties?.source || '',
        severity: this.features?.healthCheck?.properties?.severity || '-',
        updated: this.features?.healthCheck?.properties?.updated || '',
        faults: this.features?.healthCheck?.properties?.faults || undefined,
      },
    };
    return healthCheck;
  }

  getSimInfo(): DdmThingFeature<DDMThingSimInfo> | null {
    if (this.features && this.features['sim-information']) {
      const simInfo: DdmThingFeature<DDMThingSimInfo> = {
        properties: {
          status: this.features['sim-information']?.properties?.status,
          offering: this.features['sim-information']?.properties?.offering,
          dataConsumedBytes: this.features['sim-information']?.properties?.dataConsumedBytes,
          updatedAt: this.features['sim-information']?.properties?.updatedAt,
          imei: this.features['sim-information']?.properties?.imei,
          country: this.features['sim-information']?.properties?.country,
          currentSessionDurationSeconds: this.features['sim-information']?.properties?.currentSessionDurationSeconds,
          currentSessionDataConsumedBytes:
            this.features['sim-information']?.properties?.currentSessionDataConsumedBytes,
          lastUptime: this.features['sim-information']?.properties?.lastUptime,
        },
      };
      return simInfo;
    } else {
      return null;
    }
  }

  getPropertyInfo(): DdmThingFeature<DittoPropertyAnalytics> | null {
    if (this.features && this.features['submetering-property-analytics']) {
      const featureInfo = this.features['submetering-property-analytics'];
      return (featureInfo as unknown as { properties: DittoPropertyAnalytics }) ?? {};
    } else {
      return null;
    }
  }

  olderThanOneMonth(): boolean {
    if (!this.getGrid()?.properties?.receiveTime) return true;
    return ThingGridComponent.olderThanOneMonth(this.getGrid()!.properties.receiveTime);
  }

  getTimeTooltip(): string {
    if (!this.getGrid()?.properties?.receiveTime) return 'never received';
    return ThingGridComponent.olderThanOneMonth(this.getGrid()!.properties.receiveTime) ? 'Older than one month' : '';
  }

  getComControllers(): DdmNamedFeature<DdmComControllerProperty>[] | null {
    const coms: DdmNamedFeature<DdmComControllerProperty>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (feature.properties && featureId.includes('controller')) {
          coms.push({
            featureId: featureId,
            definition: feature.definition,
            properties: {
              status: feature.properties?.status,
              fault: feature.properties?.fault,
              lastTransmission: feature?.properties.lastTransmission,
              devices: feature?.properties.devices,
            },
          });
        }
      }
    }
    return coms.length > 0 ? coms : null;
  }

  getMeterings(): DdmNamedFeature<DdmMeteringProperty>[] | null {
    const meterings: DdmNamedFeature<DdmMeteringProperty>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (
          feature.properties &&
          (featureId.includes('meter') || featureId.includes('heat')) &&
          !featureId.includes('radio') &&
          feature.properties.history
        ) {
          meterings.push({
            featureId: featureId,
            definition: feature.definition,
            properties: {
              status: feature.properties?.status,
              fault: feature.properties?.fault,
              history: [],
            },
          });

          for (const history_measurement of feature.properties.history) {
            meterings[meterings.length - 1].properties.history.push({ value: history_measurement as DdmExpValue });
          }
        }
      }
    }
    return meterings.length > 0 ? meterings : null;
  }

  getReadouts(): DdmNamedFeature<DdmReadoutProperty>[] | null {
    const readouts: DdmNamedFeature<DdmReadoutProperty>[] = [];

    if (this.features) {
      for (const [featureId, feature] of Object.entries(this.features)) {
        if (featureId.includes('readout')) {
          readouts.push({
            featureId: featureId,
            definition: feature.definition,
            properties: {
              status: feature.properties?.status,
              fault: feature.properties?.fault,
              readout: feature.properties?.readout,
            },
          });
        }
      }
    }
    return readouts.length > 0 ? readouts : null;
  }

  /**
   * @returns an array of all timeseries ids of the thing
   */
  getTimeseriesIds(): string[] {
    function search(obj: any): any[] {
      let result: string[] = [];
      let key: string;
      for (key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (obj[key]._ts !== undefined && obj[key].value !== undefined && obj[key].date !== undefined) {
            result.push(obj[key]._ts);
          }
          result = result.concat(search(obj[key]));
        }
      }
      return result;
    }
    return search(this.features ?? {});
  }

  /**
   * @returns true, if the thing has timeseries; false otherwise
   */
  hasTimeseries(): boolean {
    return this.getTimeseriesIds().length > 0;
  }

  /**
   * @returns true, if the thing is a Mclimate Vicki; false otherwise
   */
  isVicki(): boolean {
    return this.getAttributes()?.manufacturer?.toLowerCase() === 'mclimate';
  }

  /**
   * @returns true, if the thing is a Mclimate Vicki; false otherwise
   */
  isTadoSRT(): boolean {
    return (
      this.getAttributes()?.manufacturer?.toLowerCase() === 'tado' &&
      this.getAttributes()?.productKey?.toLowerCase() === 'va04hl'
    );
  }

  /**
   * @returns true, if the thing is a LORAWAN_GATEWAY; false otherwise
   */
  isLoraWanGateway(): boolean {
    return this.getAttributes()?.type === 'LORAWAN_GATEWAY';
  }

  /**
   * @returns true, if the thing is a SRT
   */
  isSRT(): boolean {
    return this.getAttributes()?.type.includes('SMART_RADIATOR_THERMOSTAT') ?? false;
  }

  /**
   * @returns true, if the thing is a LoRaWAN Gateway
   */
  isLoRaGateway(): boolean {
    return this.getAttributes()?.type.includes('LORAWAN_GATEWAY') ?? false;
  }

  /**
   * @returns true, if the thing is part of a fleet; false otherwise
   */
  isPartOfFleet(): boolean {
    return this.getAttributes()?.fleetId !== undefined;
  }

  /**
   * @returns true, if the thing is a LoRaWAN gateway
   */
  isLoRaWANGateway(): boolean {
    return (
      ['Kerlink', 'Browan', 'Dragino', 'RAKwireless'].includes(this.getAttributes()?.manufacturer ?? '') ||
      this.getAttributes()?.type === 'LORAWAN_GATEWAY'
    );
  }

  hasSIMCard(): boolean {
    return this.getAttributes()?.sim !== undefined;
  }
}

export function dittoToDDMThings(things: Thing[]): DdmThing[] {
  return things.map((thing) => new DdmThing(thing));
}
