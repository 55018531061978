import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Thing } from '@eclipse-ditto/ditto-javascript-client-dom';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { catchError, EMPTY, Observable, Observer } from 'rxjs';
import { DdmMaintenanceOrder } from '../models/maintenance-orders/DdmMaintenanceOrder';
import { DdmThing, dittoToDDMThings } from '../models/things/DdmThing';
import { ThingFilter, ThingFilterPredicates, ThingRequestFilter } from '../models/things/DdmThingFilter';
import { DdmEchoHistory } from '../models/things/properties/ddmHistoryProperty';
import { AuthService } from './auth/auth.service';
import { DataService } from './data.service';
import { ServiceOrder } from '../components/organisms/devices-list/devices-list.component';
import { FilterTitle } from '../models/FilterFactory';

export type SearchThingsResponse = { items: Array<DdmThing>; cursor: string };

@Injectable({
  providedIn: 'root',
})
export class ThingsService {
  static readonly THING_NOT_FOUND_STATUS = 404;

  pageSize = 25;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
  ) {}

  setPageSize(size: number) {
    this.pageSize = size;
  }

  getAllFilteredPages = async (filters?: ThingFilter[], cursor?: string, things?: DdmThing[]) => {
    return new Promise<DdmThing[]>((resolve) => {
      if (cursor !== undefined) {
        this.getFilteredThings(filters ?? [], cursor).then((res) => {
          resolve(this.getAllFilteredPages(filters, res.cursor, [...(things ?? []), ...res.items]));
        });
      } else {
        resolve(things ?? []);
      }
    });
  };
  getAllThings(): Promise<{ items: Array<DdmThing>; cursor: string }> {
    return new Promise(() => {
      const url = DataService.SERVICES.ditto + 'search/things';

      this.dataService.getApiRequest(url).subscribe((searchthingresponse) => {
        return {
          items: dittoToDDMThings(searchthingresponse.items),
          cursor: searchthingresponse.cursor,
        };
      });
    });
  }

  createMaintenanceOrder(MO_Details: ServiceOrder): Promise<{
    orderId: string;
    results: { thingId: string; status: string; zendeskTicketId: number; zendeskTicketUrl: string }[];
    status: string;
  }> {
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.singleMaintenanceOrder;

      this.dataService.postApiRequest(url, MO_Details).subscribe(
        (resp: {
          orderId: string;
          results: { thingId: string; status: string; zendeskTicketId: number; zendeskTicketUrl: string }[];
          status: string;
        }) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  getMultiThingsActions(things: DdmThing[]): Promise<{ id: string; actions: string }[]> {
    return new Promise((resolve) => {
      const req = things.map((thing) => {
        return {
          id: thing.thingId,
          faults: thing.healthCheck?.faults ? Object.keys(thing.healthCheck?.faults as object) : [],
        };
      });
      const url = DataService.SERVICES.maintenanceOrderAction;
      this.dataService.postApiRequest(url, req).subscribe((resp) => {
        // for some reason the api is sending the response as stringified json.
        const data = JSON.parse(resp);
        resolve(data?.deviceActions || []);
      });
    });
  }

  getThingMaintenanceOrder(
    id: string,
    isByOrderId?: boolean,
  ): Promise<
    {
      businessEntityLabel: string;
      creationDate: string;
      devices: object[];
      id: string;
      lastUpdate: string;
      state: string;
    }[]
  > {
    return new Promise((resolve) => {
      const url = isByOrderId
        ? DataService.SERVICES.singleMaintenanceOrder + '/' + id
        : DataService.SERVICES.retrieveMaintenanceOrder + '?deviceId=' + id;

      this.dataService.getApiRequest(url).subscribe({
        next: (response) => {
          resolve(isByOrderId ? [response] : response);
        },
        error: () => resolve([]),
      });
    });
  }

  retrieveRunId(thingId: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.ditto + `things/${thingId}?fields=features/echo/properties/runs/`;

      this.dataService.getApiRequest(url).subscribe({
        next: (data) => {
          if (Object.keys(data).length && Object.keys(data.features.echo.properties.runs).length) {
            const runIds = Object.keys(data.features.echo.properties.runs);
            resolve(parseInt(runIds[runIds.length - 1]) + 1);
          } else {
            resolve(1);
          }
        },
        error: (error) => reject(error),
      });
    });
  }

  /**
   *
   * @param thingId to get the echo data
   * @param runId the run id to get the data for
   * @param token the auth token for the user
   * @param maxCount to know what is the maximum count expected
   * @returns it will return the echo run object while there is data and it will return only how many packages were lost on the finish of the call.
   */
  retrieveRunData(thingId: string, runId: number, token: string) {
    return new Observable<{ received: string; rssi: number; snr: number }>((observer) => {
      const url = DataService.SERVICES.ditto + `things/${thingId}`;
      const params = `fields=features/echo/properties/runs/${runId}`;
      const es = new EventSourcePolyfill(`${url}?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: 'keep-alive',
          'X-Accel-Buffering': 'no',
        },
        heartbeatTimeout: 12 * 60 * 60 * 60, // give server 12 hours timeout
      });
      es.onmessage = (event: any) => {
        if (event.data && event.data !== '') {
          try {
            const runData = Object.entries(JSON.parse(event.data).features.echo.properties.runs[runId].echos)[0][1] as {
              received: string;
              rssi: number;
              snr: number;
            }; // extract the data from object
            observer.next(runData);
          } catch {
            // api send the lost statistics on last message which should make the call completed.
            observer.complete();
            es.close();
          }
        }
      };
    });
  }

  retrieveAllRunData(thingId: string): Promise<DdmEchoHistory[]> {
    return new Promise((resolve, reject) => {
      const params = `fields=features/echo/properties/runs`;
      const url = DataService.SERVICES.ditto + `things/${thingId}` + '?' + params;

      this.dataService.getApiRequest(url).subscribe({
        next: (data) => {
          if (data?.features?.echo?.properties?.runs) {
            resolve(Object.values(data.features.echo.properties.runs));
          } else {
            resolve([]);
          }
        },
        error: reject,
      });
    });
  }

  private getThingsParams(
    filters: ThingRequestFilter[],
    cursorId: string,
    sort?: Sort,
  ): { option?: string; filter?: string } {
    const filterQuery = ThingsService.getFilterQuery(filters);
    let paramOption = '';
    if (sort && sort.active) {
      // because filtering with the * or ? operators does not work with ascending
      // sort, it is allowed to have no sort at all
      const sortQuery = ThingsService.getSortOption(sort);
      paramOption += `${sortQuery},size(${this.pageSize})`;
    }

    if (cursorId) {
      if (paramOption !== '') {
        paramOption += ',';
      }
      paramOption += `cursor(${cursorId})`;
    }

    const params: { option?: string; filter?: string } = {};

    if (paramOption) params.option = paramOption;

    if (filterQuery) params.filter = filterQuery;

    return params;
  }

  getFilteredThingsCount(filters: ThingRequestFilter[]): Promise<number> {
    const params = this.getThingsParams(filters, '');
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'search/things/count';

      this.dataService.getApiRequest(url, params).subscribe({
        next: (data) => {
          resolve(data);
        },
        // When the user enters wrong values like only - (minus) without any number then just resolve an empty set of data because no data will match any wrong filters and Ditto will return error:"404 not found" always.
        error: () => resolve(0),
      });
    });
  }

  getFilteredThings(
    filters: ThingRequestFilter[],
    cursorId: string,
    sort?: Sort,
  ): Promise<{
    items: DdmThing[];
    cursor: string;
    params?: { option?: string; filter?: string };
  }> {
    const params = this.getThingsParams(filters, cursorId, sort);
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'search/things';

      this.dataService.getApiRequest(url, params).subscribe({
        next: (data) => {
          resolve({
            items: dittoToDDMThings(data.items),
            cursor: data.cursor,
          });
        },
        // When the user enters wrong values like only - (minus) without any number then just resolve an empty set of data because no data will match any wrong filters and Ditto will return error:"404 not found" always.
        error: () =>
          resolve({
            items: dittoToDDMThings([]),
            cursor: '',
            params: params,
          }),
      });
    });
  }

  async getAllFilteredThings(filters: ThingRequestFilter[], sort?: Sort): Promise<DdmThing[]> {
    let cursor = '';
    let things: DdmThing[] = [];
    do {
      const r = await this.getFilteredThings(filters, cursor, sort);
      cursor = r.cursor;
      things = things.concat(r.items);
    } while (cursor);
    return things;
  }

  getThingMO(thingId: string): Promise<DdmMaintenanceOrder[]> {
    const params = { deviceId: thingId };
    return new Promise((resolve) => {
      const url = DataService.SERVICES.retrieveMaintenanceOrder;
      this.dataService.getApiRequest(url, params).subscribe((data) => {
        resolve(data);
      });
    });
  }

  getMultiThingsMO(thingsId: string[]): Promise<{ id: string; state: string }[]> {
    const params = { deviceIds: thingsId };
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.singleMaintenanceOrder + '/state';
      this.dataService
        .getApiRequest(url, params)
        .pipe(
          catchError((err) => {
            console.error(err);
            reject(err);
            return EMPTY;
          }),
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  getThingBySerial(serial: string): Promise<DdmThing | null> {
    return new Promise((resolve) => {
      const params = { filter: `eq(attributes/serial,'${serial}')` };
      const url = DataService.SERVICES.ditto + 'search/things';

      this.dataService.getApiRequest(url, params).subscribe((data) => {
        resolve(data.items[0] ? new DdmThing(data.items[0]) : null);
      });
    });
  }

  getThing(id: string): Promise<DdmThing> {
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.ditto + 'things/' + id;

      this.dataService.getApiRequest(url).subscribe({
        next: (d) => {
          resolve(new DdmThing(d));
        },
        error: (error) => reject(error),
      });
    });
  }

  getThingRevision(id: string): Promise<number | undefined> {
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.ditto + 'things/' + id + '?fields=_revision';

      this.dataService.getApiRequest(url).subscribe({
        next: (d) => {
          resolve(new DdmThing(d)._revision);
        },
        error: (error) => reject(error),
      });
    });
  }

  getDittoDeviceHistory(
    thingId: string,
    revision: number = 0,
    recordsCount: number = 0,
  ): Observable<{ datetime: string; revision: number; context: any }> {
    return new Observable<{ datetime: string; revision: number; context: any }>((observer) => {
      const data: {
        datetime: string;
        revision: number;
        context: any;
      }[] = [];
      this.getRevisionBatch(0, thingId, revision, revision, recordsCount, data, observer)
        .then(() => {
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  getRevisionBatch(
    batch: number,
    thingId: string,
    toRevision: number,
    fromRevision: number,
    recordsCount: number,
    data: { datetime: string; revision: number }[],
    observer: Observer<{ datetime: string; revision: number; context: any }>,
    count = 0,
  ): Promise<{ datetime: string; revision: number; context: any }[]> {
    const maxRevision = 100;
    return new Promise(async () => {
      if (fromRevision === toRevision) {
        fromRevision = toRevision - maxRevision > 0 ? toRevision - maxRevision : 0;
      }
      const token = await this.authService.getAuthToken();
      const url = DataService.SERVICES.ditto + `things/${thingId}`;
      const params = `fields=_revision,features,_context,_modified&from-historical-revision=${fromRevision}&to-historical-revision=${toRevision}`;
      const es = new EventSourcePolyfill(`${url}?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      es.onmessage = async (event: any) => {
        if (event.data && event.data !== '') {
          const updatedThing = JSON.parse(event.data);
          if (count > maxRevision) {
            es.close();
            observer.complete();
          }
          // Ditto now is returning revision is 0 when there is no more historic data
          if (updatedThing._revision === 0) {
            const receiveTime = updatedThing?.features?.grid?.properties?.receiveTime || updatedThing._modified;
            count++;
            updatedThing._context.value = {
              features: { ...updatedThing._context.value?.features, ...updatedThing.features },
            };
            observer.next({ datetime: receiveTime, revision: updatedThing._revision, context: updatedThing._context });
            es.close();
            observer.complete();
          } else {
            const receiveTime = updatedThing?.features?.grid?.properties?.receiveTime || updatedThing._modified;
            if (!updatedThing._revision) {
              es.close();
              observer.complete();
            } else {
              count++;
              updatedThing._context.value = {
                features: { ...updatedThing._context.value?.features, ...updatedThing.features },
              };
              observer.next({
                datetime: receiveTime,
                revision: updatedThing._revision,
                context: updatedThing._context,
              });
            }
          }
          if (updatedThing._revision && updatedThing._revision === toRevision) {
            // after reaching the end of this historic data batch we still did not find enough historic uplink data then retrieve next historic data batch
            es.close();
            batch++;
            toRevision = toRevision - maxRevision;
            fromRevision = fromRevision - maxRevision >= 0 ? fromRevision - maxRevision : 0;
            if (toRevision > 0) {
              this.getRevisionBatch(batch, thingId, toRevision, fromRevision, recordsCount, data, observer, count);
            } else {
              es.close();
              observer.complete();
            }
          }
        }
      };
    });
  }

  getThings(ids: string[]): Promise<DdmThing[]> {
    return new Promise(() => {
      const url = ids.reduce((p: string, d: string) => p + d + ',', DataService.SERVICES.ditto + 'things?ids=');

      this.dataService.getApiRequest(url).subscribe((data) => {
        dittoToDDMThings(data);
      });
    });
  }

  createThing(thing: Thing, thingTemplate: string): Promise<Thing> {
    return this.getThing(thing.id).then(
      () => {
        // if the device already exists then reject as we did not create a new device.
        return Promise.reject(
          new HttpErrorResponse({
            error: {
              message: 'Thing with given id already exists',
            },
          }),
        );
      },
      (error) => {
        if (error.message.includes(ThingsService.THING_NOT_FOUND_STATUS)) {
          return this.putThing(thing, thingTemplate);
        } else {
          return Promise.reject(error);
        }
      },
    );
  }

  putThing(thing: Thing, thingTemplate: string): Promise<Thing> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'things/' + thing.id;

      this.dataService.putApiRequest(url, JSON.parse(thingTemplate)).subscribe((response) => {
        resolve(response);
      });
    });
  }

  echoToThing(thingId: string, runId: number, delay: number, count: number): Observable<Thing> {
    return new Observable((observer) => {
      const url = DataService.SERVICES.ditto + 'things/' + thingId + '/features/echo/inbox/messages/run';
      const req = {
        numberOfEchos: count,
        delay: delay,
        runId: runId,
      };

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: () => {
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  toggleChildLock(thingId: string, newState: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/childLock/inbox/messages/changeConfigurationProperties';
      const req = {
        enabled: newState,
      };

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setHeartBeatConfiguration(thingId: string, interval: number, nth?: number | null): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/heartBeat/inbox/messages/changeConfigurationProperties';
      const req: {
        intervalInMinutes: number;
        requestAckEveryNthMessage?: number;
      } = {
        intervalInMinutes: interval,
      };
      if (nth) {
        req['requestAckEveryNthMessage'] = nth;
      }

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  recalibrateDevice(thingId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url = DataService.SERVICES.ditto + 'things/' + thingId + '/features/thermostat/inbox/messages/calibrate';

      this.dataService.postApiRequest(url, {}, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  resynchroniseDeviceTime(thingId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto + 'things/' + thingId + '/features/thermostat/outbox/messages/timeRequested';

      this.dataService.postApiRequest(url, {}, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setTargetTemperature(thingId: string, targetTemperature: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/temperatureControl/inbox/messages/changeTargetTemperature';
      const req = JSON.stringify(targetTemperature);

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setTemperatureOffset(thingId: string, temperatureOffset: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/temperatureControl/inbox/messages/changeTemperatureOffset';
      const req = JSON.stringify(temperatureOffset);

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setMotorLimit(thingId: string, newLimit: number) {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/thermostat/inbox/messages/changeMotorLimitConfiguration';
      const req = { limitedPathInMicroMeters: newLimit };

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data: any) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setHydraulicBalancing(thingId: string, newStatus: boolean) {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto +
        'things/' +
        thingId +
        '/features/thermostat/inbox/messages/changeExcludedFromHydraulicBalancing';
      const req = JSON.stringify(newStatus);

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data: any) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  setOperationalMode(thingId: string, operationalMode: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url =
        DataService.SERVICES.ditto + 'things/' + thingId + '/features/operationalMode/inbox/messages/changeMode';
      const req = JSON.stringify(operationalMode);

      this.dataService.postApiRequest(url, req, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  rebootDevice(thingId: string, isGateway?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const url = isGateway
        ? DataService.SERVICES.ditto + 'things/' + thingId + '/inbox/messages/reboot'
        : DataService.SERVICES.ditto + 'things/' + thingId + '/features/thermostat/inbox/messages/reboot';

      this.dataService.postApiRequest(url, {}, {}, 'text', { 'content-type': 'application/json' }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  getThingAddress(
    thingId: string,
    buildingId: string,
    propertyId: string,
  ): Observable<{ street: string; houseNumber: string; zipCode: string; city: string }> {
    return new Observable((observer) => {
      const url = DataService.SERVICES.ditto + 'things/io.beyonnex.building:' + buildingId + '/inbox/messages/address';
      const headers = {
        buildingId: buildingId,
        propertyId: propertyId,
      };

      this.dataService.postApiRequest(url, undefined, undefined, undefined, headers).subscribe({
        next: (data) => {
          observer.next(JSON.parse(data));
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  deleteThing(id: string): Promise<null> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'things/' + id;

      this.dataService.deleteApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  setDesiredProperty(id: string, feature: string, desiredPropertyPath: string, value: any): Promise<void> {
    return new Promise((resolve) => {
      const url =
        DataService.SERVICES.ditto + `things/${id}/features/${feature}/desiredProperties/${desiredPropertyPath}`;

      this.dataService.putApiRequest(url, value).subscribe((response) => {
        resolve(response);
      });
    });
  }

  setProperty(id: string, feature: string, propertyPath: string, value: any): Promise<void> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + `things/${id}/features/${feature}/properties/${propertyPath}`;

      this.dataService.putApiRequest(url, value).subscribe((response) => {
        resolve(response);
      });
    });
  }

  setAttribute(id: string, attribute: string, value: any): Promise<void> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + `things/${id}/attributes/${attribute}`;

      this.dataService.putApiRequest(url, value).subscribe((response) => {
        resolve(response);
      });
    });
  }

  /**
  https://www.eclipse.org/ditto/http-api-doc.html#/Things-Search/get_search_things

  Returns a query for the filtering of things:
  f.e. and(or(eq(attribute1,value1),eq(attribute1,value2)),or(eq(attribute2,value3)), like(attributes/serial,'value4'))
  if no filter available: null
  */
  static getFilterQuery(unchangedFilters: ThingRequestFilter[]): string | null {
    let filters: ThingRequestFilter[] = [];

    unchangedFilters.forEach((filter) => {
      filters.push({ ...filter, active: [...filter.active] });
    });

    filters = handleDeviceStatusFilters(filters);

    const requiredFilters = filters.filter((filter) => {
      return filter.active.length > 0;
    });

    const queries = requiredFilters.map((filter) => {
      //allow multiple searches separated by comma and make a smart search:
      if (
        filter.predicate === ThingFilterPredicates.LIKE ||
        filter.predicate === ThingFilterPredicates.INSENSITIVE_LIKE
      ) {
        const activeFilters = filter.active;
        filter.active = [];
        activeFilters.forEach((input) => {
          input = `*${input}*`;
          input = input.replaceAll(' ', '*');
          filter.active.push(input);
        });
      }

      // build query string
      return `${filter.mustNotMatch ? 'not(' : ''}${filter.logical}(${filter.active.map((active) => {
        let query = '';
        if (active === 'UNKNOWN' && filter.active.length > 0) {
          query = `not(exists(${filter.property}))`;

          // unknown property can be either does not exist or false for Advanced Registration Meter
          if (filter.property === 'attributes/location/isAdvancedRegistrationMeter') {
            query += `,eq(${filter.property},false)`;
          }
          return query;
        } else if (filter.falseOrNotExists && filter.active[0] === 'false') {
          return `not(exists(${filter.property})),eq(${filter.property},false)`;
        } else if (filter.supportIntAndString) {
          return `like(${filter.property},"*${active}*"),eq(${filter.property},${active})`;
        } else if (!Array.isArray(filter.property)) {
          return `${filter.predicate}(${filter.property}${filter.predicate === ThingFilterPredicates.EXISTS ? '' : `,${filter.isNumber ? '' : '"'}${active}${filter.isNumber ? '' : '"'}`})`;
        } else {
          let innerQuery: string = '';
          filter.property.forEach((property) => {
            innerQuery += `${filter.predicate}(${property}${filter.predicate === ThingFilterPredicates.EXISTS ? '' : `,${filter.isNumber ? '' : '"'}${active}${filter.isNumber ? '' : '"'}`}),`;
          });
          return innerQuery.slice(0, -1);
        }
      })})${filter.mustNotMatch ? ')' : ''}`;
    });

    if (queries.length === 0) {
      return null;
    } else {
      return `and(${queries.join(',')})`;
    }
  }

  private static getSortOption(sort: Sort): string {
    return `sort(${sort.direction == 'asc' ? '+' : '-'}${sort.active})`;
  }

  /**
   * Polls ditto for thing creation and returns the thing id, timeouts after $timeout seconds
   * @param id - thingId
   * @param timeout - in sec
   * @returns Promise<DdmThing> of the thing to wait for
   */
  waitForThingCreation(id: string, timeout: number): Promise<DdmThing> {
    return new Promise(async (resolve, reject) => {
      if (timeout <= 0) {
        reject(new Error('Timeout exceeded'));
      } else {
        try {
          const thing = await this.getThing(id);
          resolve(thing);
        } catch (error) {
          setTimeout(() => {
            this.waitForThingCreation(id, timeout - 1);
          }, 1000 * timeout);
        }
      }
    });
  }

  /**
   * Polls ditto for thing deletion, timeouts after $timeout seconds
   * @param id - thingId
   * @param timeout - in sec
   * @returns Promise<void>
   */
  waitForThingDeletion(id: string, timeout: number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (timeout <= 0) {
        reject(new Error('Timeout exceeded'));
      } else {
        try {
          await this.getThing(id);
          // console.log('Thing still exists -> ' + thing.thingId + " after " + timeout + " seconds to go");
          setTimeout(() => this.waitForThingDeletion(id, timeout - 1), 1000);
        } catch (error) {
          // console.log("deleted")
          resolve();
        }
      }
    });
  }
}

function handleDeviceStatusFilters(filters: ThingRequestFilter[]): ThingRequestFilter[] {
  const relevantTitles = new Set([
    FilterTitle.DEVICE_ANALYSIS,
    FilterTitle.DEVICE_CONNECTIVITY,
    FilterTitle.DEVICE_INSTALLATION,
    FilterTitle.DEVICE_INTERNAL,
    FilterTitle.DEVICE_STATUS,
  ]);

  return filters.map((filter) =>
    relevantTitles.has(filter.title as FilterTitle) && filter.active.includes('50') && !filter.active.includes('60')
      ? { ...filter, active: [...filter.active, '60'] }
      : filter,
  );
}
