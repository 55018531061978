<mat-card class="flex flex-col w-full h-full">
  <div class="flex flex-row items-center space-x-2 pb-4">
    <mat-icon class="icon-display">hub</mat-icon>

    <div class="subtitle-2">{{ "things.thing.grid.title" | translate }}</div>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <!-- Item paar-->
    <div class="flex flex-col" *ngFor="let line of gridInfo; let i = index">
      <div class="flex flex-col" *ngFor="let element of line.elements; let j = index">
        <div class="flex flex-row items-center ddm-border-right {{ element.className }}">
          <ng-container *ngIf="i === 0 && j === 1">
            <div class="ddm-font-color-grey mb-4 ddm-border-right pr-2">
              {{
                element.content && isValidDate(element.content)
                  ? (element.content | date: "dd.MM.yyy")
                  : element.content
              }}
            </div>

            <div class="ddm-font-color-grey mb-4 pl-2">
              {{ isValidDate(element.content) ? (element.content | date: "H:mm:ss") : "-" }}
            </div>
          </ng-container>

          <ng-container *ngIf="i !== 0 || j !== 1">
            <div class="{{ element.className }}">
              {{ element.content }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mat-card>
