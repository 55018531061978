import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import { MbusJson } from '../models/MbusJson';

@Injectable({
  providedIn: 'root',
})
export class MBusService {
  mBusMessage = '';
  mBusKey = '';
  mBusJson = '';
  mBusExtJson = '';
  mBusTemplate = '';
  jsonResult?: MbusJson;
  jsonExtResult?: MbusJson;

  constructor(private dataService: DataService) {}

  resetFields() {
    this.mBusMessage = '';
    this.mBusKey = '';
    this.mBusJson = '';
    this.mBusExtJson = '';
    this.mBusTemplate = '';
    this.jsonResult = undefined;
    this.jsonExtResult = undefined;
  }

  parseMBus(message: string, key: string): Observable<any> {
    this.jsonResult = undefined;
    this.jsonExtResult = undefined;

    return forkJoin([this.renderMBusObservable(message, key, false), this.renderMBusObservable(message, key, true)])
      .pipe(
        map(([json, jsonExt]) => {
          this.jsonResult = MbusJson.fromJson(json);
          if (this.mBusJson == '') this.mBusJson = JSON.stringify(json, null, 2);
          this.jsonExtResult = jsonExt;
          this.jsonExtResult = MbusJson.fromJson(jsonExt);
          this.mBusMessage = message;
          this.mBusKey = key;
          return [json, jsonExt];
        }),
      )
      .pipe(
        catchError((err) => {
          this.jsonResult = undefined;
          this.jsonExtResult = undefined;
          this.mBusJson = '';
          return throwError(() => new Error(err));
        }),
      );
  }

  renderMBusObservable(message: string, key: string, ext: boolean): Observable<any> {
    return this.dataService.autoAuthRequestObservable(
      this.dataService.getDataResultBigInt,
      DataService.SERVICES.mbusParserDecode,
      undefined,
      { message: message, key: key, ext: ext ? '1' : '0' },
    );
  }

  sendMBusToDDM(mbus: any): Observable<any> {
    const url = DataService.SERVICES.mbusgateway + '/import';
    return this.dataService.postApiRequest(url, mbus);
  }
}
