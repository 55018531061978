import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { AuthService } from 'src/app/services';

@Component({
  selector: 'ddm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  childrenItems = ['/mbus', '/els', '/history', '/aes-keys', '/templates', '/mbusdeliver', '/qsmp'];
  userName: string = '';
  env = environment;
  devToolsOpen = false;
  url: string = '';
  protected readonly canViewFuota$ = this.authService.canViewFuota();

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
    let browserRefresh = true;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = false;
        this.url = event.url;
      }
    });
    setTimeout(() => {
      if (browserRefresh) {
        this.devToolsOpen = this.childrenItems.includes(this.router.url);
      }
    }, 500);
  }

  ngOnInit(): void {
    this.userName = this.authService.getCurrentUser()?.getUsername() ?? '';
  }
}
