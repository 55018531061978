import { NgModule } from '@angular/core';
import { MenuItemComponent } from './menu-item.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [MenuItemComponent],
  exports: [MenuItemComponent],
  imports: [CommonModule, MatIconModule, RouterModule, CdkAccordionModule],
})
export class MenuItemModule {}
