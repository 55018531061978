import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Policy } from '@eclipse-ditto/ditto-javascript-client-dom';

// TODO: only for testing, remove before production
@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  PUT_POLICY_BODY =
    '{"entries": {"DEFAULT": {"subjects": {"cognito:internal:ditto": {"type": "' +
    'the creator"}},"resources": {"policy:/": {"grant": ["READ","WRITE"],"revoke": []},"thing:/"' +
    ': {"grant": ["READ","WRITE"],"revoke": []},"message:/": {"grant": ["READ","WRITE"],"revoke": ' +
    '[]}}}}}';

  constructor(private dataService: DataService) {}

  getPolicy(id: string): Promise<Policy> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'policies/' + id;
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  putPolicy(id: string): Promise<Policy> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'policies/' + id;
      this.dataService.putApiRequest(url, JSON.parse(this.PUT_POLICY_BODY)).subscribe((response) => {
        resolve(response);
      });
    });
  }

  deletePolicy(id: string): Promise<null> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.ditto + 'policies/' + id;
      this.dataService.deleteApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }
}
