import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbNavigationComponent } from './breadcrumb-navigation.component';
import { BreadcrumbModule } from '../../atoms';
import { LocationService } from '../../../services';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BreadcrumbNavigationComponent],
  exports: [BreadcrumbNavigationComponent],
  imports: [CommonModule, MatIconModule, RouterModule, BreadcrumbModule],
  providers: [LocationService],
})
export class BreadcrumbNavigationModule {}
