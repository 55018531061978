import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBackButtonComponent } from './nav-back-button.component';
import { MatIconModule } from '@angular/material/icon';
import { BackButtonModule } from '../../../directives';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NavBackButtonComponent],
  exports: [NavBackButtonComponent],
  imports: [CommonModule, MatIconModule, BackButtonModule, TranslateModule],
})
export class NavBackButtonModule {}
