import { Thing } from '@eclipse-ditto/ditto-javascript-client-dom';
import { MbusType } from '../DeviceType';

export class DDMThingStatus {
  constructor() {}

  public since: Date | null = null;
  public status: string = '';
  public message: string = '';
  public fault: boolean = false;

  getStatusClass(): string {
    return this.fault ? 'text-red-500' : 'text-green-500';
  }

  getStatusMessage(): string {
    return `${this.since || ''} ${this.message}`;
  }

  static fromThing(thing: Thing): DDMThingStatus | null {
    const t = <any>thing;
    const f = MbusType.Instance.featureFromQDSType(t.attributes?.type);
    const r = new DDMThingStatus();
    if (!t.features || !t.features[f]?.properties) return null;
    r.fault = t.features[f]?.properties?.fault !== undefined && t.features[f]?.properties?.fault !== null;
    if (t.features[f]?.properties?.fault?.date) r.since = new Date(t.features[f]?.properties?.fault.date);
    r.message = t.features[f]?.properties?.fault?.messages || '';
    return r;
  }
}
