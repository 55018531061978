<div class="ddm-editor-frame">
  <ddm-error-box [errorMessage]="error" [additionalErrorInfo]="additionalErrorInfo"></ddm-error-box>
  <div class="ddm-editor-header flex items-center justify-between" *ngIf="title || icons || withClose || dropdownItems">
    <div class="ddm-editor-title">
      <div class="subtitle-2 light-font">{{ this.title }}</div>
    </div>
    <div>
      <div class="ddm-editor-header-actions">
        <button mat-icon-button *ngIf="withClose" (click)="withClose()">
          <mat-icon class="ddm-editor-header-actions">close</mat-icon>
        </button>
      </div>
      <div class="ddm-editor-header-actions" *ngIf="withClose">
        <div class="ddm-border-vert-0"></div>
      </div>
      <!-- icon buttons top right -->
      <div class="ddm-editor-header-actions" *ngFor="let icon of icons" matTooltip="{{ icon.tooltip || '' }}">
        <button mat-icon-button (click)="icon.callBack()">
          <mat-icon class="{{ icon.className }}">
            {{ icon.describer }}
          </mat-icon>
        </button>
      </div>
      <!-- dropdown menu -->
      <div class="ddm-editor-header-actions" *ngIf="dropdownItems">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          matBadge="{{ dropdownBadge || null }}"
          matBadgeColor="primary"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let menuItem of dropdownItems"
          mat-menu-item
          matTooltip="{{ menuItem.tooltip || '' }}"
          matTooltipPosition="left"
          (click)="menuItem.callBack()"
          class="{{ menuItem.className }}"
        >
          {{ menuItem.describer }}
        </button>
      </mat-menu>
      <!--nested dropdown menu-->
      <div class="ddm-editor-header-actions" *ngIf="nestedDropdownItems">
        <button
          mat-icon-button
          [matMenuTriggerFor]="nestedMenu"
          matBadge="{{ nestedDropdownBadge || null }}"
          matBadgeColor="primary"
        >
          <mat-icon matTooltip="{{ 'template-editor.suggestions' | translate }}">more_vert</mat-icon>
        </button>
      </div>
      <mat-menu #nestedMenu="matMenu">
        <button
          *ngFor="let parentItem of nestedDropdownItems"
          mat-menu-item
          matTooltip="{{ parentItem.tooltip || '' }}"
          matTooltipPosition="left"
          [matMenuTriggerFor]="childItemMenu"
          class="{{ parentItem.className }}"
        >
          {{ parentItem.describer }}
          <mat-menu #childItemMenu="matMenu">
            <button
              *ngFor="let childItem of parentItem.childItems"
              mat-menu-item
              matTooltip="{{ childItem.tooltip || '' }}"
              matTooltipPosition="left"
              (click)="childItem.callBack()"
              class="{{ childItem.className }}"
            >
              {{ childItem.describer }}
            </button>
          </mat-menu>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="ddm-editor-ace">
    <ace id="ace-editor" [config]="editorConfig" [value]="value" (valueChange)="valueChange.emit($event)"></ace>
  </div>
  <div class="ddm-editor-footer cantered-content flex flex-row-reverse items-center gap-2" *ngIf="buttons">
    <div class="ddm-editor-header-actions" *ngFor="let button of buttons">
      <!-- Since dynamic directives dont work in angular: -->
      <!-- stroked -->
      <button
        *ngIf="button.className === 'mat-stroked-button'"
        mat-stroked-button
        color="primary"
        (click)="button.callBack()"
      >
        {{ button.describer }}
      </button>
      <!-- raised -->
      <button
        *ngIf="button.className === 'mat-raised-button'"
        mat-raised-button
        color="primary"
        (click)="button.callBack()"
      >
        {{ button.describer }}
      </button>
      <!-- flat -->
      <button
        *ngIf="button.className === 'mat-flat-button'"
        mat-flat-button
        color="primary"
        (click)="button.callBack()"
      >
        {{ button.describer }}
      </button>
    </div>
  </div>
</div>
