export class DeviceType {
  id?: string = undefined;
  manufacturerNamespace?: string; // optional, shall be e.g. com.qundis
  manufacturerId: string = '*'; // required
  deviceType: string = '*'; // required, whole number or *
  deviceVersion: string = '*'; // requred, whole number or *
  charge: string = '*'; // required
  edition: string = '*'; // required
  protocol: string = '*'; // required
  sortOrder: number = 100; // The order of relevance
  disabled: boolean = false;
  templates?: { type: string; value: string }[];
  idScriptlet?: string;
  _created?: Date; // Date of creation
  _modified?: Date; // Date of modifikation
  _creator?: string; // E-Mail Address of creator (get from JWT)
  description: string = ''; // allows markdown formatting
  productionPeriod?: { from: string; to: string }; // optional
  documentation?: // optional
  {
    EN: { title: string; url: string };
    DE: { title: string; url: string };
  }[];
  comments?: string; // to comments see: https://docs.mongodb.com/manual/reference/database-references/#std-label-dbref-explanation
  revision?: number; // revision number of the device type

  applyFromForm(value: any) {
    Object.assign(this, value);

    if (this.productionPeriod?.from) {
      const date = new Date(this.productionPeriod.from);
      this.productionPeriod.from = `${date.getFullYear()}-${date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
    }
    if (this.productionPeriod?.to) {
      const date = new Date(this.productionPeriod.to);
      this.productionPeriod.to = `${date.getFullYear()}-${date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
    }

    if (!this.productionPeriod?.from || !this.productionPeriod?.to) {
      this.productionPeriod = undefined;
    }
  }

  getTemplateDisplayInfo(templateType: string): TemplateDisplayInfo {
    switch (templateType) {
      case 'elk':
      case 'glue':
      case 'ditto':
        return { data: this.getTemplateValue(templateType), format: 'velocity' };
      case 'schema':
        return { data: this.idScriptlet, format: 'json' };
      default:
        console.error('Template type ' + templateType + ' is unknown');
        return { format: 'unknown' };
    }
  }

  private getTemplateValue(templateType: string): string {
    let value = '';

    this.templates?.forEach((element) => {
      if (element.type === templateType) {
        value = element.value;
      }
    });

    console.log('Template type ' + templateType + ' is unknown. Using empty value.');
    return value;
  }

  setTemplateData(templateType: string, value: string) {
    switch (templateType) {
      case 'schema':
        this.idScriptlet = value;
        break;
      default:
        this.setTemplateValue(templateType, value);
        break;
    }
  }

  private setTemplateValue(templateType: string, value: string) {
    if (!this.templates) this.templates = [];

    let found = false;

    this.templates?.forEach((element) => {
      if (templateType === element.type) {
        element.value = value;
        found = true;
      }
    });

    if (!found) {
      this.templates.push({ type: templateType, value: value });
    }
  }

  /**
   * Returns the Id of the device type. This IMPL HAS TO BE the same implementation than the implementation on the Service side.
   * If not, the CRUD mechanism wont work anymore!
   * @returns falsly "" if no valid serviceId could be generated.
   */
  getServiceId(): string {
    return `${this.manufacturerId}-${this.deviceType}-${this.deviceVersion}-${this.charge}-${this.edition}-${this.protocol}`;
  }
}

export interface TemplateDisplayInfo {
  data?: string;
  format: string;
}

export class MbusType {
  private ttypedict: { [key: number]: string };
  private static typedict: { [key: string]: number } = {
    OTHER: 0x00,
    OIL_METER: 0x01,
    ELECTRICITY_METER: 0x02,
    GAS_METER: 0x03,
    HEAT_METER: 0x04,
    STEAM_METER: 0x05,
    WARM_WATER_METER: 0x06,
    WATER_METER: 0x07,
    HEAT_COST_ALLOCATOR: 0x08,
    COMPRESSED_AIR: 0x09,
    COOLING_METER_OUTLET: 0x0a,
    COOLING_METER_INLET: 0x0b,
    HEAT_METER_INLET: 0x0c,
    HEAT_COOLING_METER: 0x0d,
    BUS_SYSTEM_COMPONENT: 0x0e,
    UNKNOWN: 0x0f,
    RESERVED_FOR_METER_16: 0x10,
    RESERVED_FOR_METER_17: 0x11,
    RESERVED_FOR_METER_18: 0x12,
    RESERVED_FOR_METER_19: 0x13,
    CALORIFIC_VALUE: 0x14,
    HOT_WATER_METER: 0x15,
    COLD_WATER_METER: 0x16,
    DUAL_REGISTER_WATER_METER: 0x17,
    PRESSURE_METER: 0x18,
    AD_CONVERTER: 0x19,
    SMOKE_DETECTOR: 0x1a,
    ROOM_SENSOR_TEMP_HUM: 0x1b,
    GAS_DETECTOR: 0x1c,
    RESERVED_FOR_SENSOR_0X1D: 0x1d,
    RESERVED_FOR_SENSOR_0X1E: 0x1e,
    RESERVED_FOR_SENSOR_0X1F: 0x1f,
    BREAKER_ELEC: 0x20,
    VALVE_GAS_OR_WATER: 0x21,
    RESERVED_FOR_SWITCHING_DEVICE_0X22: 0x22,
    RESERVED_FOR_SWITCHING_DEVICE_0X23: 0x23,
    RESERVED_FOR_SWITCHING_DEVICE_0X24: 0x24,
    CUSTOMER_UNIT_DISPLAY_DEVICE: 0x25,
    RESERVED_FOR_CUSTOMER_UNIT_0X26: 0x26,
    RESERVED_FOR_CUSTOMER_UNIT_0X27: 0x27,
    WASTE_WATER_METER: 0x28,
    GARBAGE: 0x29,
    RESERVED_FOR_CO2: 0x2a,
    RESERVED_FOR_ENV_METER_0X2B: 0x2b,
    RESERVED_FOR_ENV_METER_0X2C: 0x2c,
    RESERVED_FOR_ENV_METER_0X2D: 0x2d,
    RESERVED_FOR_ENV_METER_0X2E: 0x2e,
    RESERVED_FOR_ENV_METER_0X2F: 0x2f,
    RESERVED_FOR_SYSTEM_DEVICES_0X30: 0x30,
    COM_CONTROLLER: 0x31,
    UNIDIRECTION_REPEATER: 0x32,
    BIDIRECTION_REPEATER: 0x33,
    RESERVED_FOR_SYSTEM_DEVICES_0X34: 0x34,
    RESERVED_FOR_SYSTEM_DEVICES_0X35: 0x35,
    RADIO_CONVERTER_SYSTEM_SIDE: 0x36,
    RADIO_CONVERTER_METER_SIDE: 0x37,
    RESERVED_FOR_SYSTEM_DEVICES_0X38: 0x38,
    RESERVED_FOR_SYSTEM_DEVICES_0X39: 0x39,
    RESERVED_FOR_SYSTEM_DEVICES_0X3A: 0x3a,
    RESERVED_FOR_SYSTEM_DEVICES_0X3B: 0x3b,
    RESERVED_FOR_SYSTEM_DEVICES_0X3C: 0x3c,
    RESERVED_FOR_SYSTEM_DEVICES_0X3D: 0x3d,
    RESERVED_FOR_SYSTEM_DEVICES_0X3E: 0x3e,
    RESERVED_FOR_SYSTEM_DEVICES_0X3F: 0x3f,
    RESERVED: 0xff,
  };

  constructor() {
    this.ttypedict = {};
    for (const n in MbusType.typedict) {
      this.ttypedict[MbusType.typedict[n]] = n;
    }
  }

  private static _instance = new MbusType();
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public static intFromString(type: string): number {
    return this.typedict[type];
  }

  public static decodeQDSType(type: string): string {
    if (['pico', 'fevo', 'GW', 'LORAWAN_GATEWAY'].includes(type)) return 'LoRa Gateway';
    else if (['SRT', 'SMART_RADIATOR_THERMOSTAT'].includes(type)) return 'Smart Radiator Thermostat';
    else if (['LORAWAN_MBUS_CONCENTRATOR'].includes(type)) return 'MBus Concentrator';
    else if (type === 'FIELD_TEST_DEVICE') return 'Fieldtester';
    else if (type === 'ROOM') return 'Room';
    else return new MbusType().ttypedict[parseInt(type)];
  }

  public featureFromQDSType(type: string | null): string {
    if (type == null) return 'unknown';
    return this.ttypedict[parseInt(type)]?.toLowerCase().replace(/_/g, '-') || 'unknown';
  }
}
