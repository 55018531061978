import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { registerLocaleData } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SwUpdate } from '@angular/service-worker';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'brace';
import 'brace/mode/json';
import 'brace/mode/text';
import 'brace/mode/velocity';
import 'brace/theme/github';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService } from './services';
import { SidenavService } from './services/sidenav/sidenav.service';

const LANGUAGES = ['en', 'de'];
const DATE_LOCALES_FROM_LANG = new Map([
  ['en', 'en-US'],
  ['de', 'de-DE'],
]);

@Component({
  selector: 'ddm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav?: MatSidenav;

  protected readonly sidenavData$ = this.sidenavService.sidenavData$;

  env = environment;
  isLoggedIn = false;
  isMenuOpen = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    readonly swUpdate: SwUpdate,
    private readonly sidenavService: SidenavService,
    private dateAdapter: DateAdapter<any>, // one for the angular date picker (has no time support)
    private ngxDateAdapter: NgxMatDateAdapter<any>, // one for the ngx datetime picker (has time support)
  ) {
    // Force-update app on changes
    if (swUpdate.isEnabled) {
      swUpdate.checkForUpdate().then((updateAvailable) => {
        if (updateAvailable) {
          swUpdate.activateUpdate().then((updateActivated) => {
            if (updateActivated) {
              document.location.reload();
            }
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated();

    this.translate.langs = LANGUAGES;
    this.translate.defaultLang = LANGUAGES[0];

    this.dateAdapter.setLocale(DATE_LOCALES_FROM_LANG.get(this.translate.currentLang));
    this.ngxDateAdapter.setLocale(DATE_LOCALES_FROM_LANG.get(this.translate.currentLang));

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((langChangeEvent: LangChangeEvent) => {
      const newLanguage = LANGUAGES.includes(langChangeEvent.lang) ? langChangeEvent.lang : this.translate.defaultLang;
      localStorage.setItem('language', newLanguage);
      this.translate.use(newLanguage);

      const currentDateLocale = DATE_LOCALES_FROM_LANG.get(langChangeEvent.lang);
      let definedDateLocale = 'en-US';
      if (currentDateLocale !== undefined) {
        definedDateLocale = currentDateLocale;
      }
      localStorage.setItem('locale', definedDateLocale);
      this.dateAdapter.setLocale(definedDateLocale);
      this.ngxDateAdapter.setLocale(definedDateLocale);

      registerLocaleData(langChangeEvent.lang);
    });

    const selectedLanguage =
      localStorage.getItem('language') || this.translate.getBrowserLang() || this.translate.defaultLang;
    this.translate.use(selectedLanguage);

    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.classList.add('dark');
    }

    this.handleSidenavData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.authService.logout();
  }

  protected handleSidenavOpenedChange(opened: boolean): void {
    if (opened) {
      return;
    }

    this.sidenavService.close();
  }

  private handleSidenavData(): void {
    this.sidenavService.sidenavData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      data.opened ? this.sidenav?.open() : this.sidenav?.close();
    });
  }
}
