<div [class.meta-table-container]="overlay" [class.inlay-table-container]="!overlay">
  <div [class.meta-table]="overlay" [class.inlay-table]="!overlay" *ngIf="listEnabled">
    <table class="w-full">
      <tr *ngFor="let line of tableLines; let i = index">
        <td *ngFor="let element of line.elements; let j = index">
          <span class="{{ element.className }}" matTooltip="{{ element.tooltip }}">
            <ng-container *ngIf="isHistory && j === 0">
              <div class="flex flex-row items-center ddm-border-right">
                <div class="ddm-font-color-grey ddm-border-right pr-2">{{ element.content | date: "dd.MM.yyy" }}</div>
                <div class="ddm-font-color-grey pl-2">{{ element.content | date: "HH:mm:ss" }}</div>
              </div>
            </ng-container>
            <ng-container *ngIf="isStatus && i === 0 && j === 1">
              <div class="flex flex-row items-center ddm-border-right">
                <div class="ddm-font-color-grey ddm-border-right pr-2">
                  {{ Date.parse(element.content) > 0 ? (element.content | date: "dd.MM.yyy") : element.content }}
                </div>
                <div class="ddm-font-color-grey pl-2" *ngIf="Date.parse(element.content) > 0">
                  {{ Date.parse(element.content) > 0 ? (element.content | date: "HH:mm:ss") : element.content }}
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="(!isHistory && !isStatus) || (isStatus && (j !== 1 || i !== 0)) || (isHistory && j !== 0)"
            >
              {{
                parseFloat(element.content)
                  ? parseFloat(element.content).toLocaleString("de")
                  : parseUnit(element.content) !== "units." + element.content
                    ? parseUnit(element.content)
                    : element.content
              }}
            </ng-container>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>
