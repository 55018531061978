import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfoListPopupComponent } from './info-list-popup.component';

@NgModule({
  declarations: [InfoListPopupComponent],
  exports: [InfoListPopupComponent],
  imports: [CommonModule, MatTooltipModule],
})
export class InfoListPopupModule {}
