import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserTabTitleService {
  constructor(
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
  ) {}

  initialize() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.getPageTitle(event.url).subscribe((title) => {
          this.titleService.setTitle(`bxInsight - ${title}`);
        });
      }
    });
  }

  private getPageTitle(url: string): Observable<string> {
    switch (true) {
      case url.startsWith('/business-entities'):
        return this.translateService.get('side-bar.business-entities');
      case url.startsWith('/devices'):
        return this.translateService.get('side-bar.devices');
      case url.startsWith('/gateways'):
        return this.translateService.get('side-bar.gateways');
      case url.startsWith('/maintenance-orders'):
        return this.translateService.get('side-bar.maintenance-orders');
      case url.startsWith('/fleetmanagement'):
        return this.translateService.get('side-bar.fleet-management');
      case url.startsWith('/fuota'):
        return this.translateService.get('side-bar.fuota');
      case url.startsWith('/els'):
        return this.translateService.get('side-bar.els');
      case url.startsWith('/mbusdeliver'):
        return this.translateService.get('side-bar.deliver-mbus');
      case url.startsWith('/mbus'):
        return this.translateService.get('side-bar.mbus');
      case url.startsWith('/history'):
        return this.translateService.get('side-bar.history');
      case url.startsWith('/aes-keys'):
        return this.translateService.get('side-bar.aes-keys');
      case url.startsWith('/settings'):
        return this.translateService.get('side-bar.settings');
      default:
        return of('bxInsight');
    }
  }
}
