import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ElsUploadService {
  constructor(private dataService: DataService) {}

  sendElsFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      const url = DataService.SERVICES.elsCsvImporter + 'els-upload-file';
      this.dataService.postApiRequest(url, formData, { reportProgress: true, observe: 'events' }, 'blob').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  sendXmlFile(file: File, kek: string): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      const url = `${DataService.SERVICES.elsCsvImporter}els?kek=${kek}`;
      this.dataService.postApiRequest(url, formData, { reportProgress: true, observe: 'events' }, 'blob').subscribe({
        next: (response) => {
          resolve(response);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
