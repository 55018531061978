import { Component, Input } from '@angular/core';
import { LocationService } from '../../../services';

@Component({
  selector: 'ddm-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
})
export class BreadcrumbNavigationComponent {
  @Input() onLinkClick?: Function;

  public pathSegments: readonly string[][] = [];

  constructor(locationService: LocationService) {
    this.pathSegments = locationService.getSegmentPaths();
  }
}
