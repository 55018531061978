/* This module contains the main menu sidebar included in the home component */
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { RouterModule } from '@angular/router';
import { MenuItemModule } from '../../atoms';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [CommonModule, TranslateModule, MenuItemModule, RouterModule],
})
export class SidebarModule {}
