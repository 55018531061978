import { DdmThing } from './DdmThing';
import { fault } from './properties/DdmHealthCheckProperty';

export class DDMThingHealthcheck {
  constructor() {}

  public updatedAt: string = '-';
  public severity: string = '';
  public source: string = '';
  faults?: { [key: string]: fault }[];

  getSeverity(): string {
    return this.severity;
  }

  getSourceMessage(): string {
    return `${this.updatedAt || ''} ${this.source}`;
  }

  static fromThing(thing: DdmThing): DDMThingHealthcheck | null {
    const health = thing.getHealthCheck();

    const r = new DDMThingHealthcheck();
    r.severity = health?.properties?.severity ?? '-';
    r.source = health?.properties?.source ?? '';
    r.updatedAt = health?.properties?.updated ?? '';
    r.faults = health?.properties?.faults ?? undefined;
    return r;
  }
}
