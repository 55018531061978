import { DdmThing } from './DdmThing';

export class DDMThingLocation {
  constructor() {}

  public label: string = '';
  public humanReadableId: string = '';
  public floorLevel: string = '';
  public buildingId: string = '';
  public businessEntityId: string = '';
  public externalId: string = '';
  public roomtype: string = '';
  public mountedOn: string = '';
  public isAdvancedRegistrationMeter: string = '';

  getLabel(): string {
    return this.label;
  }

  getHumanReadableId(): string {
    return this.humanReadableId;
  }
  getPropertyId(): string {
    return this.businessEntityId;
  }
  getBuildingId(): string {
    return this.buildingId;
  }

  getExternalId(): string {
    return this.externalId;
  }
  getFloorlevel(): string {
    return this.floorLevel;
  }
  getRoomtype(): string {
    return this.roomtype;
  }
  getMountedOn(): string {
    return this.mountedOn;
  }

  static fromThing(thing: DdmThing): DDMThingLocation | null {
    const attributes = thing.getAttributes();

    const r = new DDMThingLocation();
    r.floorLevel = attributes?.location?.room?.floorLevel ?? '-';
    r.label = attributes?.location?.label ?? '-';
    r.humanReadableId = attributes?.humanReadableId ?? '-';
    r.roomtype = attributes?.location?.room?.type ?? '-';
    r.externalId = attributes?.location?.crmId ?? '-';
    r.buildingId = attributes?.location?.buildingId ?? '-';
    r.businessEntityId = attributes?.location?.businessEntityId ?? '-';
    return r;
  }
}
