import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AceConfigInterface } from 'ngx-ace-wrapper';
import { EditorAction, NestedDropdownItem } from 'src/app/models/Editor';
import 'brace/ext/searchbox';

@Component({
  selector: 'ddm-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  // Callback for the ace´s value
  @Output() valueChange = new EventEmitter<string>();

  // Ace´s initial value
  @Input() value: string = '';

  // If this callback is set, the X close button is rendered in the top right
  @Input() withClose?: Function;

  // Title rendered in the top left
  @Input() title?: string;

  // maps to ace´s configuration. Currently json and velocity are supported
  @Input() format?: string;

  // List of buttons rendered in the bottom right
  @Input() buttons?: EditorAction[];

  // List of icon button rendered in the top left beside the close button
  @Input() icons?: EditorAction[];

  // List with items in the dropdown for additional actions
  @Input() dropdownItems?: EditorAction[];

  // Badge for the dropdown
  @Input() dropdownBadge?: number | null;

  // Nested list with items for an item dropdown.
  @Input() nestedDropdownItems?: NestedDropdownItem[];

  // Badge for the nested dropdown
  @Input() nestedDropdownBadge?: number | string;

  // Messages for error box
  @Input() error?: string;
  @Input() additionalErrorInfo?: string;

  jsonconfig: AceConfigInterface = {
    mode: 'json',
    theme: 'github',
  };

  velocityconfig: AceConfigInterface = {
    mode: 'velocity',
    theme: 'github',
  };

  textconfig: AceConfigInterface = {
    mode: 'txt',
    theme: 'github',
  };

  editorConfig = this.jsonconfig;

  ngOnInit() {
    switch (this.format) {
      case 'velocity':
        this.editorConfig = this.velocityconfig;
        break;
      case 'json':
        this.editorConfig = this.velocityconfig;
        break;
      case 'txt':
        this.editorConfig = this.textconfig;
        break;
      default:
        console.error('Unknown editor config: ' + this.format);
    }
  }
}
