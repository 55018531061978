import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

// Service is not intended to be provided in root
@Injectable()
export class LocationService {
  private readonly segmentPaths: string[][];
  private readonly queryParams: { key: string; value: string }[];

  constructor(private location: Location) {
    this.segmentPaths = this.location
      .path()
      .split('/')
      .filter((s) => s != '')
      .map((segments, index, src) => {
        return src.slice(0, index + 1).map((segment) => segment.split('?')[0]);
      });
    this.queryParams =
      this.location
        .path()
        .split('?')
        .pop()
        ?.split('&')
        .map((param) => {
          const p = param.split('=');
          return { key: p[0], value: p[1] };
        }) || [];
  }

  getCurrentSegmentsPath(): readonly string[] {
    return this.segmentPaths[this.segmentPaths.length - 1] || [];
  }

  getSegmentPaths(): readonly string[][] {
    return this.segmentPaths;
  }

  getQueryParams(): readonly { key: string; value: string }[] {
    return this.queryParams;
  }

  getQueryValue(key: string): string | undefined {
    return this.queryParams.find((s) => s.key === key)?.value;
  }
}
