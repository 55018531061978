import { NgModule } from '@angular/core';
import { BackButtonDirective } from './back-button.directive';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BackButtonDirective],
  exports: [BackButtonDirective],
  imports: [RouterModule],
})
export class BackButtonModule {}
