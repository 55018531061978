<div class="ddm-content-back-btn">
  <button
    ddmBackButton
    class="body-1 light-font"
    [disabled]="disabled"
    [beforeRedirect]="onBack"
    [preserveParams]="preserveParams"
  >
    <mat-icon>arrow_back</mat-icon>
    {{ "navBackButton.back" | translate }}
  </button>
</div>
