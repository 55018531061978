import { TranslateModule } from '@ngx-translate/core';
import { ErrorBoxComponent } from './error-box.component';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ErrorBoxComponent],
  exports: [ErrorBoxComponent],
  imports: [CommonModule, MatIconModule, TranslateModule],
})
export class ErrorBoxModule {}
