import { MbusType } from '../DeviceType';

export class DDMThingType {
  private _mbusType = MbusType.Instance;

  constructor(public type: string) {}

  public toString(): string {
    return `${MbusType.decodeQDSType(this.type)} (${this.type})`;
  }
}
