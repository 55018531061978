import { MbusType } from './DeviceType';
import { ThingFilter, ThingFilterLogic, ThingFilterPredicates } from './things/DdmThingFilter';
import { Cardinality, CARDINALITY, getSeverityName, SEVERITY_NAME } from './things/thing-util/thing-status.model';

export enum FilterTitle {
  THING_MANUFACTURER = 'manufacturer',
  THING_IS_DEVICE = 'thingIsDevice',
  ORDER_STATUS = 'orderStatus',
  THING_TYPE = 'type',
  THING_BLOCKED_THING = 'blocked_thing',
  THING_UNBLOCKED_THING = 'unblocked_thing',
  THING_SERIAL = 'serial',
  THING_ID_MULTIPLE = 'thingIDMuliple',
  THING_ID = 'thingID',
  THING_DEVEUI = 'thingDevEui',
  THING_AWSID = 'thingAwsId',
  THING_PRODUCT_KEY = 'thingProductKey',
  THING_LASTREADOUT = 'thingLastReadout',
  THING_HAS_SRT = 'thingHasSRT',
  THING_HAS_NO_LOCATION = 'thingHasNoLocation',
  THING_HAS_SUB = 'thingHasSUB',
  THING_HAS_SMOKEDETECTOR = 'thingHasSmokeDetector',
  THING_GATEWAY = 'gateway',
  THING_RSSI = 'rssi',
  THING_ADVANCED_METER = 'advancedRegistrationMeter',
  THING_ROOM_GROUP_NUMBER = 'roomGroupNumber',
  THING_SEVERITY = 'severity',
  THING_BUSINESS_ENTITY = 'businessentity',
  BUSINESS_ENTITY_ID = 'id',
  BUSINESS_ENTITY_LABEL = 'label',
  THING_BILLING_UNIT_REFERENCE = 'billingUnitReference',
  THING_NOT_CONNECTED = 'notConnected',
  MAINTENANCE_ORDER_ID = 'moId',
  BUSINESS_ENTITY_DEVICESEVERITY = 'deviceseverity',
  BUSINESS_ENTITY_BESEVERITY = 'beseverity',
  PROPERTY_DOMAIN = 'propertydomain',
  BUSINESS_ENTITY_LASTREADOUT = 'last-readout',
  ROLLOUT_NAME = 'rolloutName',
  DEVICE_GROUP = 'deviceGroup',
  FIRMWARE_VERSION = 'firmwareVersion',
  DATE = 'Date',
  DATETIME = 'DateTime',
  TIME = 'Time',
  BUSINESS_ENTITY_ADDRESS = 'address',
  BUSINESS_ENTITY_BILLING = 'billing',
  BUSINESS_ENTITY_CITY = 'city',
  BUSINESS_ENTITY_ZIP = 'zip',
  HAS_NO_UNMOUNTED_DATE = 'hasNoUnmountedDate',
  UNMOUNTED_DATE = 'unmountedDate',
  NO_UNMOUNTED_DATE = 'noUnmountedDate',
  MOUNTED_DATE = 'mountedDate',
  DEVICE_STATUS = 'deviceStatus',
  DEVICE_INSTALLATION = 'deviceInstallation',
  DEVICE_CONNECTIVITY = 'deviceConnectivity',
  DEVICE_INTERNAL = 'deviceInternal',
  DEVICE_ANALYSIS = 'deviceAnalysis',
}

export const maintenanceOrderStatus = { IN_PLANNING: 'In Planning', IN_EXECUTION: 'In Execution', DONE: 'Done' };

export class FilterFactory {
  static filterHandlers: { (title: FilterTitle): ThingFilter | null }[] = [
    handleRolloutName,
    handleDeviceGroup,
    handleFirmwareVersion,
    handleDate,
    handleDateTime,
    handleTime,
    handleManufacturer,
    handleType,
    handleBlockedThing,
    handleUnblockedThing,
    handleSerial,
    handleGateway,
    handleRssi,
    handleSeverity,
    handleBusinessentity,
    handleThingContainsSrt,
    handleThingContainsSub,
    handleThingContainsSmokeDetector,
    handleDeviceSeverity,
    handleBESeverity,
    handleBEDomain,
    handleId,
    handleThingIDMuliple,
    handleThingID,
    handleHasNoUnmountDate,
    handleUnmountDate,
    handleNoUnmountDate,
    handleMountDate,
    handleProductKey,
    handleDevEUI,
    handleAWSID,
    handleThingLastReadout,
    handleOrderStatus,
    handleThingNotConnected,
    handleLabel,
    handleBillingUnitReference,
    handleRoomGroupNumber,
    handleMoId,
    handleLastreadout,
    handleAddress,
    handleAdvancedMeter,
    handlePropertyCity,
    handlePropertyZip,
    handlePropertyBilling,
    handleThingIsDevice,
    handleDeviceStatus,
    handleDeviceInstallation,
    handleDeviceConnectivity,
    handleDeviceInternal,
    handleDeviceAnalysis,
  ];

  static createFilter(title: FilterTitle): ThingFilter | null {
    let retVal = null;
    this.filterHandlers.forEach((handler) => {
      if (handler(title) !== null) retVal = handler(title);
    });
    return retVal;
  }

  static createMultipleFilters(filterTitles: FilterTitle[]): ThingFilter[] {
    const retVal: ThingFilter[] = [];
    filterTitles.forEach((title) => {
      const filter = this.createFilter(title);
      if (filter !== null) retVal.push(filter);
    });
    return retVal;
  }
}

function handleManufacturer(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_MANUFACTURER) {
    return {
      title: FilterTitle.THING_MANUFACTURER,
      values: [
        'QDS',
        'EIE',
        'DWZ',
        'ITW',
        'Tado',
        'MClimate',
        'netvox',
        'innotas',
        'Adeunis',
        'Lobaro',
        'Browan',
        'Kerlink',
        'Dragino',
        'RAKwireless',
      ],
      active: [],
      property: 'attributes/manufacturer',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleRolloutName(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.ROLLOUT_NAME) {
    return {
      title: FilterTitle.ROLLOUT_NAME,
      values: [],
      active: [],
      property: 'ROLLOUT_NAME',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceGroup(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_GROUP) {
    return {
      title: FilterTitle.DEVICE_GROUP,
      values: [],
      active: [],
      property: 'DEVICE_GROUP',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleFirmwareVersion(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.FIRMWARE_VERSION) {
    return {
      title: FilterTitle.FIRMWARE_VERSION,
      values: [],
      active: [],
      property: 'FIRMWARE_VERSION',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDate(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DATE) {
    return {
      title: FilterTitle.DATE,
      values: [],
      active: [],
      property: 'DATE',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDateTime(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DATETIME) {
    return {
      title: FilterTitle.DATETIME,
      values: [],
      active: [],
      property: 'DATETIME',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleTime(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.TIME) {
    return {
      title: FilterTitle.TIME,
      values: [],
      active: [],
      property: 'TIME',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleType(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_TYPE) {
    return {
      title: FilterTitle.THING_TYPE,
      values: ['4', '6', '7', '8', '10', '26', '49', '55', 'LORAWAN_GATEWAY', 'SMART_RADIATOR_THERMOSTAT'], // in case of the type has any number then the type text will be retrieved by the number, but when the device type has no number then we just put it as is and show it as is.
      active: [],
      valueToLabel: (value) =>
        !Number.isNaN(parseInt(value + '')) ? `${value}: ${MbusType.decodeQDSType(value + '')}` : value + '', // the part which is before ':' which will be shown before by the value of the filter as long as it is a valid number, otherwise there will Not be any number if the type is only a text with no number in it.
      property: 'attributes/type',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleBlockedThing(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_BLOCKED_THING) {
    return {
      title: FilterTitle.THING_BLOCKED_THING,
      values: [],
      active: ['true'],
      property: 'features/firmware/properties/backend/firmwareUpdateBlocked',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleUnblockedThing(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_UNBLOCKED_THING) {
    return {
      title: FilterTitle.THING_UNBLOCKED_THING,
      values: [],
      active: [],
      property: 'features/firmware/properties/backend/firmwareUpdateBlocked',
      falseOrNotExists: true,
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleSerial(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_SERIAL) {
    return {
      title: FilterTitle.THING_SERIAL,
      values: [],
      active: [],
      property: 'attributes/serial',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleGateway(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_GATEWAY) {
    return {
      title: FilterTitle.THING_GATEWAY,
      values: [],
      active: [],
      property: 'features/grid/properties/gateway',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleRssi(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_RSSI) {
    return {
      title: FilterTitle.THING_RSSI,
      values: [],
      active: [],
      property: 'features/grid/properties/rssi',
      predicate: ThingFilterPredicates.LOWER_OR_EQUAL,
      logical: ThingFilterLogic.OR,
      isNumber: true,
    };
  } else {
    return null;
  }
}

function handleSeverity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_SEVERITY) {
    return {
      title: FilterTitle.THING_SEVERITY,
      values: ['OK', 'WARNING', 'CRITICAL', 'UNKNOWN'],
      active: [],
      property: 'features/healthCheck/properties/severity',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
      isNumber: false,
    };
  } else {
    return null;
  }
}

function handleBusinessentity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_BUSINESS_ENTITY) {
    return {
      title: FilterTitle.THING_BUSINESS_ENTITY,
      values: [],
      active: [],
      property: 'attributes/location/crmId',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingContainsSrt(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_HAS_SRT) {
    return {
      title: FilterTitle.THING_HAS_SRT,
      values: [],
      active: [],
      property: [
        'attributes/deviceTypes/LORAWAN_GATEWAY/count',
        'attributes/deviceTypes/SMART_RADIATOR_THERMOSTAT/count',
      ],
      predicate: ThingFilterPredicates.GREATER,
      isNumber: true,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleHasNoUnmountDate(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.HAS_NO_UNMOUNTED_DATE) {
    return {
      title: FilterTitle.HAS_NO_UNMOUNTED_DATE,
      values: [],
      translateableValues: true,
      active: ['false'],
      property: 'attributes/location/unmountedOn',
      predicate: ThingFilterPredicates.EXISTS,
      logical: ThingFilterLogic.NOT,
    };
  } else {
    return null;
  }
}

function handleNoUnmountDate(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.NO_UNMOUNTED_DATE) {
    return {
      title: FilterTitle.NO_UNMOUNTED_DATE,
      values: ['hasNoUnmountedDate'],
      translations: {
        hasNoUnmountedDate: 'filter.filterValue.hasNoUnmountedDate',
      },
      translateableValues: true,
      active: [],
      property: 'attributes/location/unmountedOn',
      predicate: ThingFilterPredicates.EXISTS,
      logical: ThingFilterLogic.NOT,
    };
  } else {
    return null;
  }
}

function handleUnmountDate(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.UNMOUNTED_DATE) {
    return {
      title: FilterTitle.UNMOUNTED_DATE,
      values: ['hasUnmountedDate'],
      translations: {
        hasUnmountedDate: 'filter.filterValue.hasUnmountedDate',
      },
      translateableValues: true,
      active: [],
      property: 'attributes/location/unmountedOn',
      predicate: ThingFilterPredicates.EXISTS,
      logical: ThingFilterLogic.AND,
    };
  } else {
    return null;
  }
}

function handleMountDate(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.MOUNTED_DATE) {
    return {
      title: FilterTitle.MOUNTED_DATE,
      values: ['hasMountedDate'],
      translations: {
        hasMountedDate: 'filter.filterValue.hasMountedDate',
      },
      translateableValues: true,
      active: [],
      property: 'attributes/location/mountedOn',
      predicate: ThingFilterPredicates.EXISTS,
      logical: ThingFilterLogic.AND,
    };
  } else {
    return null;
  }
}

function handleThingContainsSub(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_HAS_SUB) {
    return {
      title: FilterTitle.THING_HAS_SUB,
      values: [],
      active: [],
      property: [
        'attributes/deviceTypes/HEAT_METER/count',
        'attributes/deviceTypes/WARM_WATER_METER/count',
        'attributes/deviceTypes/WATER_METER/count',
        'attributes/deviceTypes/HEAT_COST_ALLOCATOR/count',
        'attributes/deviceTypes/COOLING_METER_OUTLET/count',
        'attributes/deviceTypes/COM_CONTROLLER/count',
      ],
      predicate: ThingFilterPredicates.GREATER,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingContainsSmokeDetector(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_HAS_SMOKEDETECTOR) {
    return {
      title: FilterTitle.THING_HAS_SMOKEDETECTOR,
      values: [],
      active: [],
      property: ['attributes/deviceTypes/SMOKE_DETECTOR/count'],
      predicate: ThingFilterPredicates.GREATER,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleBESeverity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_BESEVERITY) {
    return {
      title: FilterTitle.BUSINESS_ENTITY_BESEVERITY,
      values: ['Ok', 'Warning', 'Critical'],
      active: [],
      property: 'beseverity',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
      isNumber: false,
    };
  } else {
    return null;
  }
}

function handleBEDomain(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.PROPERTY_DOMAIN) {
    return {
      title: FilterTitle.PROPERTY_DOMAIN,
      values: ['all', 'srt', 'sub'],
      active: [],
      property: 'bedomain',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
      isNumber: false,
    };
  } else {
    return null;
  }
}

function handleDeviceSeverity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_DEVICESEVERITY) {
    return {
      title: FilterTitle.BUSINESS_ENTITY_DEVICESEVERITY,
      values: ['OK', 'Warning', 'Critical', 'Unknown'],
      active: [],
      property: 'deviceseverity',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
      isNumber: false,
    };
  } else {
    return null;
  }
}

function handleRoomGroupNumber(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_ROOM_GROUP_NUMBER) {
    return {
      title: 'roomGroupNumber',
      values: [],
      active: [],
      supportIntAndString: true,
      property: 'attributes/location/roomGroup/number',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleLabel(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_LABEL) {
    return {
      title: 'label',
      values: [],
      active: [],
      property: 'label',
      predicate: ThingFilterPredicates.INSENSITIVE_LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleBillingUnitReference(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_BILLING_UNIT_REFERENCE) {
    return {
      title: 'billingUnitReference',
      values: [],
      active: [],
      property: 'billingUnitReference',
      predicate: ThingFilterPredicates.INSENSITIVE_LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleMoId(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.MAINTENANCE_ORDER_ID) {
    return {
      title: 'moId',
      values: [],
      active: [],
      property: 'moId',
      predicate: ThingFilterPredicates.INSENSITIVE_LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleAdvancedMeter(title: FilterTitle): ThingFilter | null {
  const labels = { true: 'Yes', UNKNOWN: 'No' };
  if (title === FilterTitle.THING_ADVANCED_METER) {
    return {
      title: FilterTitle.THING_ADVANCED_METER,
      values: [true, 'UNKNOWN'],
      valueToLabel: (value) => labels[value as keyof typeof labels],
      active: [],
      isNumber: true,
      property: 'attributes/location/isAdvancedRegistrationMeter',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleId(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_ID) {
    return {
      title: 'id',
      values: [],
      active: [],
      property: 'attributes/location/label',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handlePropertyBilling(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_BILLING) {
    return {
      title: 'billing',
      values: [],
      active: [],
      property: 'attributes/location/label',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingIsDevice(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_IS_DEVICE) {
    return {
      title: 'thingIsDevice',
      values: [],
      active: ['true'],
      property: 'attributes/serial',
      predicate: ThingFilterPredicates.EXISTS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handlePropertyZip(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_ZIP) {
    return {
      title: 'zip',
      values: [],
      active: [],
      property: 'zip',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handlePropertyCity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_CITY) {
    return {
      title: 'city',
      values: [],
      active: [],
      property: 'city',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingIDMuliple(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_ID_MULTIPLE) {
    return {
      title: FilterTitle.THING_ID_MULTIPLE,
      values: [],
      active: [],
      property: 'thingId',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingID(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_ID) {
    return {
      title: FilterTitle.THING_ID,
      values: [],
      active: [],
      property: 'thingId',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleProductKey(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_PRODUCT_KEY) {
    return {
      title: FilterTitle.THING_PRODUCT_KEY,
      values: [],
      active: [],
      property: 'attributes/productKey',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDevEUI(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_DEVEUI) {
    return {
      title: FilterTitle.THING_DEVEUI,
      values: [],
      active: [],
      property: 'attributes/devEUI',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleAWSID(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_AWSID) {
    return {
      title: FilterTitle.THING_AWSID,
      values: [],
      active: [],
      property: 'attributes/fleetId/iotcore',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleOrderStatus(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.ORDER_STATUS) {
    return {
      title: FilterTitle.ORDER_STATUS,
      values: ['IN_PLANNING', 'IN_EXECUTION', 'DONE'],
      active: [],
      valueToLabel: (value) => {
        return maintenanceOrderStatus[value as keyof typeof maintenanceOrderStatus];
      },
      property: 'attributes/type',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingNotConnected(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_NOT_CONNECTED) {
    return {
      title: 'notConnected',
      values: [],
      active: [],
      property: 'features/healthCheck/properties/updated',
      predicate: ThingFilterPredicates.LOWER,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleAddress(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_ADDRESS) {
    return {
      title: 'address',
      values: [],
      active: [],
      property: 'address',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleLastreadout(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.BUSINESS_ENTITY_LASTREADOUT) {
    return {
      title: 'last-readout',
      values: [],
      active: [],
      property: 'lastReadout',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleThingLastReadout(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.THING_LASTREADOUT) {
    return {
      title: FilterTitle.THING_LASTREADOUT,
      values: [],
      active: [],
      property: 'features/healthCheck/properties/severity',
      predicate: ThingFilterPredicates.LIKE,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceStatus(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_STATUS) {
    return {
      title: FilterTitle.DEVICE_STATUS,
      values: [
        CARDINALITY.EMPTY,
        CARDINALITY.UNKNOWN,
        CARDINALITY.OK,
        CARDINALITY.PENDING,
        CARDINALITY.WARNING,
        CARDINALITY.ERROR,
      ],
      active: [],
      valueToLabel: (value) => {
        return getSeverityName(parseInt(value as string) as Cardinality);
      },
      isNumber: true,
      property: 'features/deviceStatus/properties/status/severity/cardinality',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceInstallation(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_INSTALLATION) {
    return {
      title: FilterTitle.DEVICE_INSTALLATION,
      values: [
        CARDINALITY.EMPTY,
        CARDINALITY.UNKNOWN,
        CARDINALITY.OK,
        CARDINALITY.PENDING,
        CARDINALITY.WARNING,
        CARDINALITY.ERROR,
      ],
      active: [],
      valueToLabel: (value) => {
        return getSeverityName(parseInt(value as string) as Cardinality);
      },
      isNumber: true,
      property: 'features/deviceStatus/properties/status/dimensions/installation/severity/cardinality',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceConnectivity(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_CONNECTIVITY) {
    return {
      title: FilterTitle.DEVICE_CONNECTIVITY,
      values: [
        CARDINALITY.EMPTY,
        CARDINALITY.UNKNOWN,
        CARDINALITY.OK,
        CARDINALITY.PENDING,
        CARDINALITY.WARNING,
        CARDINALITY.ERROR,
      ],
      active: [],
      valueToLabel: (value) => {
        return getSeverityName(parseInt(value as string) as Cardinality);
      },
      isNumber: true,
      property: 'features/deviceStatus/properties/status/dimensions/connectivity/severity/cardinality',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceInternal(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_INTERNAL) {
    return {
      title: FilterTitle.DEVICE_INTERNAL,
      values: [
        CARDINALITY.EMPTY,
        CARDINALITY.UNKNOWN,
        CARDINALITY.OK,
        CARDINALITY.PENDING,
        CARDINALITY.WARNING,
        CARDINALITY.ERROR,
      ],
      active: [],
      valueToLabel: (value) => {
        return getSeverityName(parseInt(value as string) as Cardinality);
      },
      isNumber: true,
      property: 'features/deviceStatus/properties/status/dimensions/internal/severity/cardinality',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}

function handleDeviceAnalysis(title: FilterTitle): ThingFilter | null {
  if (title === FilterTitle.DEVICE_ANALYSIS) {
    return {
      title: FilterTitle.DEVICE_ANALYSIS,
      values: [
        CARDINALITY.EMPTY,
        CARDINALITY.UNKNOWN,
        CARDINALITY.OK,
        CARDINALITY.PENDING,
        CARDINALITY.WARNING,
        CARDINALITY.ERROR,
      ],
      active: [],
      valueToLabel: (value) => {
        return getSeverityName(parseInt(value as string) as Cardinality);
      },
      isNumber: true,
      property: 'features/deviceStatus/properties/status/dimensions/analysis/severity/cardinality',
      predicate: ThingFilterPredicates.EQUALS,
      logical: ThingFilterLogic.OR,
    };
  } else {
    return null;
  }
}
