import { Component, Input } from '@angular/core';
import { PopupListLine } from 'src/app/models/PopupList';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ddm-info-list-popup',
  templateUrl: './info-list-popup.component.html',
  styleUrls: ['./info-list-popup.component.scss'],
})
export class InfoListPopupComponent {
  Date = Date;
  @Input() listEnabled = true;
  @Input() overlay = true;
  @Input() isHistory = false;
  @Input() isStatus = false;
  @Input() tableLines?: PopupListLine[];
  parseFloat = parseFloat;

  constructor(private translate: TranslateService) {}

  parseUnit(unit: string): string | null {
    if (unit !== '0') {
      return this.translate.instant(`units.${unit}`);
    } else {
      return null;
    }
  }
}
