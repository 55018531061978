import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ddm-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent implements OnChanges {
  // Will be translated
  @Input() errorMessage?: string;

  // Will not be translated
  @Input() additionalErrorInfo?: string;

  // The icon that shall be displayed along the error message
  @Input() icon?: string;

  // Time after which the error box will fade out
  @Input() messageTimeoutMS?: number;

  renderedErrorMessage?: string;
  renderedAdditionalErrorInfo?: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorMessage || changes.additionalErrorInfo) {
      this.renderedErrorMessage = this.errorMessage;
      this.renderedAdditionalErrorInfo = this.additionalErrorInfo;

      if (this.messageTimeoutMS) {
        setTimeout(() => {
          this.renderedErrorMessage = '';
          this.renderedAdditionalErrorInfo = '';
        }, this.messageTimeoutMS);
      }
    }
  }
}
