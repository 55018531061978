import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class IdentifierService {
  constructor(private dataService: DataService) {}

  getDeviceTypesForJSON(json: any): Promise<any> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.identifier + 'devicetypes';
      this.dataService.postApiRequest(url, json).subscribe((resp) => {
        resolve(resp);
      });
    });
  }

  evictCache(): Promise<string> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.identifier + '/clearAllCaches';
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }

  validateSchema(schema: string, json: string): Promise<any> {
    let s: any;
    let j: any;
    try {
      s = JSON.parse(schema);
    } catch {
      return Promise.reject(new Error('schema is not valid json'));
    }

    try {
      j = JSON.parse(json);
    } catch {
      return Promise.reject(new Error('sample is not valid json'));
    }
    return new Promise((resolve) => {
      const url = DataService.SERVICES.identifier + 'validate';
      this.dataService.postApiRequest(url, { schema: s, json: j }, undefined, 'json').subscribe((resp) => {
        resolve(resp);
      });
    });
  }
}
