import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, mergeMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RouterLinkActive } from '@angular/router';
import { CdkAccordionItem } from '@angular/cdk/accordion';

@Component({
  selector: 'ddm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements AfterContentInit, AfterViewInit, OnChanges {
  @ContentChildren(MenuItemComponent)
  content: QueryList<MenuItemComponent> = new QueryList();

  @Input()
  icon = '';
  @Input() isChild? = false;
  @Input()
  text = '';
  @Input()
  iconSide = '';
  @Input()
  callBack = Function();

  @Input() set toggle(state: boolean) {
    setTimeout(() => {
      this.accordion.expanded = state;
    }, 500);
  }
  @Input()
  linkPath = '';
  @Input() highlight = true;

  @ViewChild('isActive', { static: false })
  rla!: RouterLinkActive;

  @ViewChild('accordionItem', { static: false })
  accordion!: CdkAccordionItem;

  hasContent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  id = Math.floor(Math.random() * 0xbad).toString();

  ngAfterContentInit(): void {
    this.hasContent$.next(this.content.length > 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggle?.currentValue !== undefined && this.isChild === false && this.accordion) {
      this.accordion.expanded = true;
    }
  }
  ngAfterViewInit(): void {
    this.hasContent$
      .pipe(
        filter((hasContent: boolean) => hasContent),
        mergeMap(() => {
          return this.content.first.rla.isActiveChange.asObservable();
        }),
        filter((isActive: boolean) => isActive),
      )
      .subscribe(() => {
        this.accordion.open();
      });
  }
}
