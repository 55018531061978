import { ObjectValues } from '../../core.model';

export const CARDINALITY = {
  CRITICAL: 60,
  ERROR: 50,
  WARNING: 40,
  PENDING: 30,
  OK: 20,
  UNKNOWN: 10,
  EMPTY: 0,
} as const;

export const SEVERITY_NAME = {
  CRITICAL: 'CRITICAL',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  PENDING: 'PENDING',
  OK: 'OK',
  UNKNOWN: 'UNKNOWN',
  EMPTY: 'EMPTY',
} as const;

export type Cardinality = ObjectValues<typeof CARDINALITY>;

export type SeverityName = ObjectValues<typeof SEVERITY_NAME>;

interface Critical {
  name: typeof SEVERITY_NAME.CRITICAL;
  cardinality: typeof CARDINALITY.CRITICAL;
}

interface Error {
  name: typeof SEVERITY_NAME.ERROR;
  cardinality: typeof CARDINALITY.ERROR;
}

interface Warning {
  name: typeof SEVERITY_NAME.WARNING;
  cardinality: typeof CARDINALITY.WARNING;
}

interface Pending {
  name: typeof SEVERITY_NAME.PENDING;
  cardinality: typeof CARDINALITY.PENDING;
}

interface Ok {
  name: typeof SEVERITY_NAME.OK;
  cardinality: typeof CARDINALITY.OK;
}

interface Unknown {
  name: typeof SEVERITY_NAME.UNKNOWN;
  cardinality: typeof CARDINALITY.UNKNOWN;
}

interface Empty {
  name: typeof SEVERITY_NAME.EMPTY;
  cardinality: typeof CARDINALITY.EMPTY;
}

export type Severity = Critical | Error | Warning | Pending | Ok | Unknown | Empty;

interface StatusData {
  severity: Severity;
  updatedAt: string;
}

interface SubState extends StatusData {
  message: string;
  source: string;
}

export interface DeviceStatusDimension extends StatusData {
  subStates: Record<string, SubState>;
}

export interface DeviceStatus extends StatusData {
  dimensions: {
    connectivity?: DeviceStatusDimension;
    internal?: DeviceStatusDimension;
    installation?: DeviceStatusDimension;
    analysis?: DeviceStatusDimension;
  };
}

export function isCardinality(cardinality?: Cardinality): cardinality is Cardinality {
  if (!cardinality && cardinality !== 0) {
    return false;
  }

  return Object.values(CARDINALITY).includes(cardinality);
}

export function getSeverityName(severityName: Cardinality): SeverityName {
  switch (severityName) {
    case CARDINALITY.EMPTY:
      return SEVERITY_NAME.EMPTY;
    case CARDINALITY.ERROR:
      return SEVERITY_NAME.ERROR;
    case CARDINALITY.OK:
      return SEVERITY_NAME.OK;
    case CARDINALITY.PENDING:
      return SEVERITY_NAME.PENDING;
    case CARDINALITY.UNKNOWN:
      return SEVERITY_NAME.UNKNOWN;
    case CARDINALITY.WARNING:
      return SEVERITY_NAME.WARNING;
    case CARDINALITY.CRITICAL:
      return SEVERITY_NAME.CRITICAL;
  }
}
