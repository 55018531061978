<cdk-accordion>
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    role="button"
    [class.has-content]="(hasContent$ | async) && accordionItem.expanded"
    [attr.id]="'header-' + id"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'expandable-' + id"
  >
    <a
      (click)="!isChild ? callBack() : (accordion.expanded = true)"
      href="#"
      [routerLink]="highlight ? linkPath : null"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLinkActive]="highlight ? 'active' : ''"
      #isActive="routerLinkActive"
      class="grid auto-cols-auto grid-flow-col-dense"
    >
      <div [class.justify-between]="iconSide !== 'left'" class="ddm-sidebar-menu-item flex items-center p-3">
        <div [class.order-1]="iconSide !== 'left'" class="pr-2">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <div>{{ text }}</div>
      </div>
      <div
        *ngIf="hasContent$ | async"
        class="flex items-center"
        (click)="$event.stopPropagation(); accordionItem.toggle(); (false)"
      >
        <mat-icon *ngIf="!accordionItem.expanded">expand_more</mat-icon>
        <mat-icon *ngIf="accordionItem.expanded">expand_less</mat-icon>
      </div>
    </a>
    <div
      *ngIf="hasContent$ | async"
      [hidden]="!accordionItem.expanded"
      [attr.id]="'expandable-' + id"
      [attr.aria-labelledby]="'header-' + id"
      role="region"
      [class.sub-menu-item]="hasContent$ | async"
    >
      <ng-content></ng-content>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
