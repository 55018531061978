export const COGNITO_GROUPS = {
  SUBMETERING_ADMIN_WRITE: 'ditto:submetering-admin-write',
  SUBMETERING_ADMIN_READ: 'ditto:submetering-admin-read',
  SMARTHEATING_ADMIN_WRITE: 'ditto:smartheating-admin-write',
  SMARTHEATING_ADMIN_READ: 'ditto:smartheating-admin-read',
  FUOTA_READ: 'fuota-write',
  FUOTA_WRITE: 'fuota-read',
  DITTO_ADMIN: 'ditto:admin',
  DITTO_CONNECT_GO: 'ditto:connect-go',
  DITTO_KALO_GO: 'ditto:kalo-go',
} as const;

export type CognitoGroups = (typeof COGNITO_GROUPS)[keyof typeof COGNITO_GROUPS];
