export type DeviceT = {
  manufacturerId: string;
  id: number;
  type: string;
  version: number;
};

type MBusDataRecordT = {
  dataLength: number;
  description: string;
  subunit: number;
  type: string;
  multiplierExponent: number;
  vib: number[];
  userDefinedDescription: string;
  unit: string;
  field: string;
  storageNumber: number;
  tariff: number;
  dib: number[];
  value: number;
  _index: number;
  _length: number;
};

export class MbusJson {
  mbus?: {
    records: MBusDataRecordT[];
    encryptionMode: string;
    moreRecordsFollow: boolean;
    status: string;
  };

  device?: DeviceT;
  headerLength?: number;
  header?: number[];
  decryptedVDR?: number[];

  public static fromJson(json: any): MbusJson {
    const r: MbusJson = Object.assign(new MbusJson(), json);
    r.indexVDR();
    return r;
  }

  public getRecords(): any[] {
    return this.mbus?.records ? this.mbus.records : [];
  }

  public getDevice(): DeviceT {
    return this.device ? this.device : <DeviceT>{};
  }

  public recordLength(record: MBusDataRecordT): number {
    return record.dataLength + record.dib.length + record.vib.length;
  }

  // adds indices and lengths to the records
  public indexVDR() {
    let index = 0;
    if (!this.mbus?.records?.length) return;

    for (let i = 0; i < this.mbus.records.length; i++) {
      const r = this.mbus.records[i];
      if (r.dib) {
        const dibvib = r.dib.concat(r.vib);
        while (!dibvib.every((dv, i) => dv === this.decryptedVDR![i + index]) && i + index < this.decryptedVDR!.length)
          index++;
      }
      r._index = index;
      if (i) {
        this.mbus.records[i - 1]._length = index - this.mbus.records[i - 1]._index;
      }
    }

    if (this.decryptedVDR) {
      this.mbus.records[this.mbus.records.length - 1]._length = this.decryptedVDR.length - index;
    }
  }
}
