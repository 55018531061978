import { Component, Input, OnInit } from '@angular/core';
import { LocationService } from '../../../services';

@Component({
  selector: 'ddm-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  pathSegment: string = '';
  currentSegment: string = '';
  isActive: boolean = false;

  constructor(private locationService: LocationService) {}

  @Input() path: string[] = [];
  @Input() last: boolean = false;
  @Input() onLinkClick?: Function | null;

  ngOnInit(): void {
    this.pathSegment = this.path[this.path.length - 1] || '';
    this.currentSegment =
      this.locationService.getCurrentSegmentsPath()[this.locationService.getCurrentSegmentsPath().length - 1] || '';
    this.isActive = this.currentSegment === this.pathSegment;
  }

  parseTitle(title: string): string {
    title = title.replaceAll('_', ' ').replaceAll('-', ' ');
    return title.charAt(0).toUpperCase() + title.slice(1);
  }
}
