import { Thing } from '@eclipse-ditto/ditto-javascript-client-dom';

export class DDMThingRSSI {
  constructor(public rssi: number) {}

  public rssiLevel() {
    // @grischa, put this into Model
    if (this.rssi >= -69) return 'superior';
    if (this.rssi >= -81) return 'excellent';
    if (this.rssi >= -94) return 'good';
    if (this.rssi >= -100) return 'moderate';
    if (this.rssi >= -111) return 'too-low';
    return 'out-of-range';
  }

  getRSSIClass(): string {
    return 'rssi-' + this.getRSSIMessage();
  }

  getRSSIMessage(): string {
    if (this.rssi === undefined) return '';
    return this.rssiLevel();
  }

  toString(): string {
    return `${this.rssi}`;
  }

  static fromThing(thing: Thing): DDMThingRSSI | null {
    const t = <any>thing;
    const rssi = t?.features?.grid?.properties?.rssi;
    if (rssi === undefined) return null;
    return new DDMThingRSSI(rssi);
  }

  static categories: string[] = ['out-of-range', 'too-low', 'moderate', 'good', 'excellent', 'superior'];

  static rssiColors = [
    { name: 'out-of-range', value: '#D70000' },
    { name: 'too-low', value: '#8A0101' },
    { name: 'moderate', value: '#A75401' },
    { name: 'good', value: '#717101' },
    { name: 'excellent', value: '#609801' },
    { name: 'superior', value: '#007800' },
  ];
}
