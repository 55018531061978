<li class="breadcrumb">
  <a
    (click)="onLinkClick?.()"
    [routerLink]="['/'].concat(path)"
    queryParamsHandling="merge"
    [class.active]="isActive"
    class="body-1 light-font"
    [ngClass]="{ 'cursor-default': last }"
  >
    {{ parseTitle(pathSegment) }}
  </a>
  <span class="breadcrumb-icon-container" [class.active]="isActive">
    <mat-icon class="grey">chevron_right</mat-icon>
  </span>
</li>
