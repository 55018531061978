import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AesKeysService {
  constructor(private dataService: DataService) {}

  checkIfKeyExists(aesKey: string): Promise<boolean> {
    return new Promise((resolve) => {
      const url = DataService.SERVICES.elsCsvImporter + 'keys/' + aesKey;
      this.dataService.getApiRequest(url).subscribe((response) => {
        resolve(response);
      });
    });
  }
}
